<template>
  <div style="display: inline-block;">
    <DatePicker
      v-model="beginDate"
      v-bind="beginProps"
      :type="type"
      :clearable="clearable"
      :transfer="transfer"
      @on-change="changeBeginDate"
    ></DatePicker>
    <slot name="joiner">
      <span :class="['kebab-line', joinerClass]"></span>
    </slot>
    <DatePicker
      v-model="endDate"
      v-bind="endProps"
      :type="type"
      :clearable="clearable"
      :transfer="transfer"
      @on-change="changeEndDate"
    ></DatePicker>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "DateRange",
  props: {
    value: {
      type: Object,
      default: () => ({
        beginDate: "",
        endDate: ""
      })
    },
    keyMap: {
      type: Object,
      default: () => ({
        begin: "beginDate",
        end: "endDate"
      })
    },
    type: {
      type: String,
      default: "date"
    },
    clearable: Boolean,
    disabled: Boolean,
    transfer: Boolean,
    beginDisabled: Boolean,
    endDisabled: Boolean,
    // 是否当所选开始日期大于结束日期时自动将结束日期置为开始日期后一天
    autoNext: {
      type: Boolean,
      default: true
    },
    // 是否默认限制开始时间
    limitBegin: {
      type: Boolean,
      default: true
    },
    beginClass: [String, Array, Object],
    endClass: [String, Array, Object],
    beginHolder: {
      type: String,
      default: "请选择开始日期"
    },
    endHolder: {
      type: String,
      default: "请选择结束日期"
    },
    begin: Object,
    end: Object,
    joinerClass: [String, Object]
  },
  data() {
    return {
      beginDate: "",
      endDate: ""
    };
  },
  computed: {
    beginProps() {
      return {
        options: this.limitBegin
          ? {
              disabledDate(date) {
                return date && date.valueOf() < Date.now();
              }
            }
          : undefined,
        disabled: this.disabled || this.beginDisabled,
        placeholder: this.beginHolder,
        class: this.beginClass,
        ...this.begin
      };
    },
    endProps() {
      return {
        options: {
          disabledDate: date =>
            this.beginDate && date && date.valueOf() < this.beginDate.valueOf()
        },
        disabled: this.disabled || this.endDisabled,
        placeholder: this.endHolder,
        class: this.endClass,
        ...this.end
      };
    }
  },
  methods: {
    changeBeginDate(date) {
      if (this.beginDate > this.endDate) {
        let type = {
          date: "day",
          month: "month",
          year: "year"
        };
        this.endDate = this.autoNext
          ? dayjs(this.beginDate)
              .add(1, type[this.type])
              .toDate()
          : "";
      }
      this.emit();
      this.$emit("change-begin", date);
    },
    changeEndDate(date) {
      this.emit();
      this.$emit("change-end", date);
    },
    emit() {
      this.$emit("input", {
        ...this.value,
        [this.keyMap.begin]: this.beginDate,
        [this.keyMap.end]: this.endDate
      });
    },
    setBegin(newVal) {
      this.beginDate = newVal || "";
    },
    setEnd(newVal) {
      this.endDate = newVal || "";
    }
  },
  created() {
    this.beginDate = this.value[this.keyMap.begin];
    this.endDate = this.value[this.keyMap.end];
    let unWatch = this.$watch(
      "keyMap",
      val => {
        // console.log("keymap");

        // 监听对应开始日期
        this.$watch(`value.${val.begin}`, this.setBegin);

        // 监听对应结束日期
        this.$watch(`value.${val.end}`, this.setEnd);
      },
      {
        immediate: true
      }
    );
    unWatch();
  },
  mounted() {
    let i;
    for (i = 0; i < this.$el.children.length; ) {
      this.$el.parentNode.insertBefore(
        this.$el.children[this.$el.children.length - i - 1],
        this.$el.nextElementSibling
      );
    }
    this.$el.parentNode.removeChild(this.$el);
  }
};
</script>
