<template>
  <Row>
    <Col :span="span">
      <FormItem label="创建人">{{ data[keyMap.createUser] }}</FormItem>
    </Col>
    <Col :span="span">
      <FormItem label="创建时间">{{ data[keyMap.createDatetime] }}</FormItem>
    </Col>
    <Col :span="span">
      <FormItem label="修改人">{{ data[keyMap.modifyUser] }}</FormItem>
    </Col>
    <Col :span="span">
      <FormItem label="修改时间">{{ data[keyMap.modifyDatetime] }}</FormItem>
    </Col>
  </Row>
</template>

<script>
export default {
  name: "UserTime",
  props: {
    span: {
      type: [Number, String],
      default: () => 12
    },
    data: {
      type: Object,
      default: () => ({})
    },
    keyMap: {
      type: Object,
      default: () => ({
        createUser: "createUser",
        createDatetime: "createDatetime",
        modifyUser: "modifyUser",
        modifyDatetime: "modifyDatetime"
      })
    }
  }
};
</script>
