
<template>
  <div
    ref="queryDock"
    class="query-dock"
    :class="{ colorful: !pure }"
    :style="{ paddingTop: paddingTop }"
  >
    <div
      class="query-main clearfix"
      :class="{
        'query-fixed': fixed,
        expanded: value,
        shadow: shadowOnFix,
        'pad-tool': showTool && value && padTool
      }"
      :style="{ right: right }"
      @submit.prevent
    >
      <div class="query-line">
        <slot></slot>
      </div>
      <div v-if="showTool" v-show="value" class="query-bottom-tool">
        <!-- :style="{ paddingRight: right && right + 'px' }" -->
        <Checkbox v-model="fixed" @on-change="changeFix">固定筛选框</Checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QueryDock',
  /* model: {
    prop: "value"
  },*/
  props: {
    value: Boolean,
    pure: Boolean,
    showTool: {
      type: Boolean,
      default: true
    },
    shadowOnFix: Boolean,
    padTool: Boolean,
    model: { // 绑定需要重置值的对象
      type: Object,
      default: () => ({})
    },
    reset: {
      type: Function,
      default: () => () => {}
    },
    exclusion: { // 重置数据时不赋值的key集合
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      fixed: false,
      paddingTop: undefined,
      right: undefined
    }
  },
  mounted() {
    if (typeof this.model !== 'object') return
    this.initModel = {
      ...this.model
    }
    let originalReset = this.reset
    this.$emit(
      'update:reset',
      typeof originalReset === 'function'
        ? () => {
          this.resetModel()
          originalReset()
        }
        : this.resetModel
    )
  },
  methods: {
    changeFix(val) {
      if (val) {
        let unit = 'px'
        let rect = this.$refs.queryDock.getBoundingClientRect()
        this.paddingTop = rect.height + unit
        this.right = document.documentElement.clientWidth - rect.right + unit

        /* let app = this.$root.$el,
          parent = this.$refs.queryDock.parentNode,
          getComputedStyle = window.getComputedStyle,
          barArr = ["auto", "scroll", "overlay"];
        while (parent !== document) {
          if (
            parent.clientHeight >= parent.scrollHeight ||
            barArr.indexOf(getComputedStyle(parent).overflowY) === -1
          ) {
            parent = parent.parentNode;
            continue;
          }
          let style = getComputedStyle(app);
          this.right =
            parent.offsetWidth -
            parent.clientWidth -
            parseInt(style.borderRightWidth) -
            parseInt(style.borderLeftWidth) +
            unit;
          break;
        } */
        return
      }
      this.right = this.paddingTop = undefined
    },
    resetModel() {
      let exclusion = this.exclusion || []
      for (let k in this.initModel) {
        if (exclusion.indexOf(k) !== -1) continue
        this.model[k] = this.initModel[k]
      }
    }
  }
}
</script>

<style lang="less">
@filter: query-;

.@{filter}dock {
  &.colorful {
    margin-bottom: 10px;
    background: #f7f7f7;

    .@{filter}main {
      background: #f7f7f7;
    }
  }
  .@{filter} {
    &main {
      position: relative;
      z-index: 33;
      padding: 10px 10px 0;
    }
    &line {
      &:not(:first-child) {
        display: none;
        // margin-top: 10px;
      }
      > .ivu-btn {
        margin-bottom: 10px;
      }
    }
    &box {
      > .ivu-btn {
        margin-bottom: 10px;
      }
    }
    &item {
      margin-bottom: 10px;

      &:not(:last-child) {
        margin-right: 10px;
      }
      &.last {
        margin-right: 0;
      }
    }
    &item-gap {
      display: inline-block;
      width: 10px;
      text-align: center;
    }
    &dis {
      margin-bottom: 10px;
    }
    &label {
      display: inline-block;
      padding-right: 8px;
      vertical-align: middle;
    }
    &fixed {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      padding: 10px 10px 0;

      &.shadow {
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1),
          0 0px 10px rgba(0, 0, 0, 0.1);
      }
    }
    &bottom-tool {
      position: absolute;
      right: 0;
      bottom: 4px;
      line-height: 1;
    }
  }
  .expanded {
    &.pad-tool {
      padding-bottom: 30px;
    }
    .@{filter}line {
      display: block;
    }
  }

  .ivu-date-picker,
  .ivu-cascader {
    vertical-align: middle;
  }
}
.ivu-modal-body {
  .@{filter} {
    &dock.colorful .@{filter}main {
      padding: 10px 10px 0;
    }
    &main {
      padding: 0;
    }
    &dock .@{filter}item {
      width: 15%;
      min-width: 120px;
    }
  }
}
</style>
