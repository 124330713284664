<template>
    <div id="app" class="app">
      <router-view />
    </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    gotoHome(pages, item) {
      if (!item.children) {
        this.$router.push('/')
      }
    },
    goto(pages, item) {
      this.$router.push(item.url)
    },
  },
};
</script>

<style lang="less">
.app{
  height: 100vh;overflow-y: auto;
  background: url("./assets/images/page-bg.png") 100% 100%;
}

.ivu-card-head{
  background: #eee;
}
.ivu-checkbox-wrapper{
  margin-bottom: 5px;
}
.ivu-table-fixed-header thead tr th,
.ivu-table-header thead tr th{
  padding: 15px 0;
  text-align: left;
}
.ivu-layout{
  background: #f5f5f5;
  //overflow-x: auto!important;
}
.ivu-layout.ivu-layout-has-sider>.ivu-layout, .ivu-layout.ivu-layout-has-sider>.ivu-layout-content{
  min-width: 900px!important;
}
.main-page{
  border: 15px solid #f5f5f5;
  box-sizing: border-box;
  background: #fff;
}

//@media screen and (max-width: 1200px) {
//  .app{
//    >* {
//      scale: 0.8;
//      transform: translate(-10%, -10%);
//    }
//  }
//}
//@media all
//and (-moz-min-device-pixel-ratio: 1.1) and (-moz-max-device-pixel-ratio: 1.2),
//(-webkit-min-device-pixel-ratio: 1.1) and (-webkit-max-device-pixel-ratio: 1.2),
//(min-resolution: 1.1dppx) and (max-resolution: 1.2dppx) {
//  :root {
//    font-size: 14px;
//  }
//  .app{
//    >* {
//      scale: 0.9;
//      transform: translate(-5%, -5%);
//    }
//  }
//}

@media all
and (-moz-min-device-pixel-ratio: 1.2) and (-moz-max-device-pixel-ratio: 1.4),
(-webkit-min-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.4),
(min-resolution: 1.2dppx) and (max-resolution: 1.4dppx) {
  :root {
    font-size: 13px;
  }
  //.app {
  //  zoom: 80%;
  //}
  .app{
    >.index-page {
      scale: 0.8;
      transform: translate(0, -10%);
    }

    .imgDetail-page{
      scale: 0.8;
      transform: translate(0, -10%);
    }
  }
}

@media all
and (-moz-min-device-pixel-ratio: 1.4) and (-moz-max-device-pixel-ratio: 1.6),
(-webkit-min-device-pixel-ratio: 1.4) and (-webkit-max-device-pixel-ratio: 1.6),
(min-resolution: 1.4dppx) and (max-resolution: 1.6dppx) {
  :root {
    font-size: 13px;
  }
  //.app {
  //  zoom: 80%;
  //}
  .app{
    >.index-page {
      scale: 0.8;
      transform: translate(-8%, -10%);
    }
    .imgDetail-page{
      scale: 0.8;
      transform: translate(0, -10%);
      overflow-x: hidden;
    }
  }
}

//@media all
//and (-moz-min-device-pixel-ratio: 1.61) and (-moz-max-device-pixel-ratio: 1.8),
//(-webkit-min-device-pixel-ratio: 1.61) and (-webkit-max-device-pixel-ratio: 1.8),
//(min-resolution: 1.61dppx) and (max-resolution: 1.8dppx) {
//  :root {
//    font-size: 9px;
//  }
//  .app {
//    zoom: 50%;
//  }
//}
//@media all
//and (-moz-min-device-pixel-ratio: 1.81) and (-moz-max-device-pixel-ratio: 2.1),
//(-webkit-min-device-pixel-ratio: 1.81) and (-webkit-max-device-pixel-ratio: 2.1),
//(min-resolution: 1.81dppx) and (max-resolution: 2.1dppx) {
//  :root {
//    font-size: 8px;
//  }
//  .app {
//    zoom: 40%;
//  }
//}
//**或用媒体查询**
//@media (max-width: 1919px) {
//  html {
//    zoom: 80%;
//  }
//}
</style>
