/**
 * Created by ymjdev on 2017/9/20.
 */
import axios from 'axios'
import router from "../routers/router";
import {Message, Modal, Notice} from 'view-design'
import './CONFIG'
// import USER from './USER'

// 全局API
const env = process.env.NODE_ENV
const ajaxUrl = CONFIG.serviceUrl[env]

const instance = axios.create({
    baseURL: ajaxUrl,
    timeout: 120000,
    withCredentials: false
})

function getKeyUrl(keyarry) {
    var md5Str = "";
    _.forEach(keyarry, function (item) {
        if (md5Str != "") {
            md5Str += "&";
        }
        md5Str += item.k + "=" + item.v;
    });
    return md5Str
}

instance.interceptors.request.use(config => {
    // config.data.appKey = config.data.appKey || '00000'
    // config.data.format = config.data.format || 'json'
    // config.data.v = config.data.v || '5.0'
    if (config.data.noUrl != 'yes') {
        // config.url = (localStorage.getItem('connectUrl') || '') + '/dps' + config.url
        config.url = '/api' + config.url
    }

    if (config.method == 'get') {
        var keyarry = [];
        _.forEach(config.data, (v, k) => {
            keyarry.push({
                k: k,
                v: v,
            });
        });
        config.url = config.url + "?" + getKeyUrl(keyarry);
    }
    return config
})

// TODO 根据后台返回结构调整这里的代码
instance.interceptors.response.use(
    res => {
        var obj = JSON.parse(res.config.data || "{}")
        if (obj.noUrl == 'yes') {
            return res.data || {}
        } else {
            var temp = res.data || {}
            if(temp.code == 1){
                return temp.data || {}
            }
        }
    },
    err => {
        if (err.response) {
            const response = err.response || {}
            const data = response.data || {}
            if (data.status === 404) {
                Message.error(data.msg || '404错误，后台没找到')
                return Promise.reject(data)
            } else {
                if (data.error === 'ERROR_ACCESS_NEED_AUTH') {
                    // // TODO 调到登录页面去
                    // Message.error({
                    //   msg: '请登录'
                    // })
                    // USER.logout()
                    // setTimeout(() => {
                    //   location.href = '/#/login'
                    // }, 2000)
                    return Promise.reject()
                } else {
                    Message.error(data.msg || '网络有点卡顿，请稍后重试')
                    return Promise.reject(data)
                }
            }
        } else {
            Message.error('网络有点卡顿，请稍后重试')
            return Promise.reject(err)
        }
    }
)

// 具体问徐起笙
function formatUrl(str) {
    // 最后一位置变成 gop + 全小写
    // hotelopen.setting.GetAuditSubjects  -> hotelopen.setting.gopgetauditsubjects
    var temp = ''
    var arr = []
    if (str) {
        arr = str.split('.')
        arr[arr.length - 1] = 'gop' + (arr[arr.length - 1].toLowerCase())
        temp = arr.join('.')
    }
    return temp
}

const postRequest = (url, params = {}) => {
    if (url) {
        let SystemSetting = {
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'rop-appCode': 'APP_GBSZ',
            }
        }
        if (localStorage.getItem('token')) {
            SystemSetting.headers.Authorization = localStorage.getItem('token') || ''
        }
        return instance(SystemSetting)
    } else {
        Message.error('请求地址为空')
    }
}

const uploadFile = (url, params) => {
    return instance({
        method: 'post',
        url: url,
        data: params,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}
const downloadFile = (url, data) => {
    return axios({
        url: ajaxUrl + '/api' + url,
        method: "get",
        params: data,
        responseType: "blob"
    });
}
const putRequest = (url, params = {}) => {
    return instance({
        method: 'put',
        url,
        data: params,
        transformRequest: [
            function (data) {
                let ret = ''
                for (const item in data) {
                    ret += encodeURIComponent(item) + '=' + encodeURIComponent(data[item]) + '&'
                }
                return ret
            }
        ],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    })
}

const deleteRequest = url => {
    return instance({
        method: 'delete',
        url
    })
}

const getRequest = (url, params = {}) => {
    return instance({
        method: 'get',
        url,
        data: params,
    })
}

const AXIOS = {
    post: postRequest,
    uploadFile: uploadFile,
    put: putRequest,
    delete: deleteRequest,
    get: getRequest,
    downloadFile: downloadFile,
}

export default AXIOS
