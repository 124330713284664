// import Vue from 'vue'
import ViewUI from 'view-design'
// import 'view-design/dist/styles/iview.css';
import App from './App.vue'
import store from './store'
import router from "./routers/router";
import NP from 'number-precision'
// import en from 'view-design/dist/locale/en-US'
// import moment from "moment";
import _ from "underscore";
import { Message } from 'view-design'
import md5 from "js-md5";
import Carousel3d from 'vue-carousel-3d';

import { serialize } from './utils'
import components from './components'
import globalMixin from './utils/mixin'

import FETCH from './global/Fetch'
import ENUM from './global/ENUM'
import Function from './global/Function'
Vue.prototype.$md5 = md5;
Vue.prototype.$env = process.env.NODE_ENV
Vue.prototype.AXIOS = FETCH
window.AXIOS = FETCH
window.moment = moment
window._ = _
// fhc 新增的ajax，待定

components.forEach(com => {
  Vue.component(com.name, com)
})
Vue.use(Carousel3d);

Vue.use(ViewUI, {
  capture: false,
  // locale: map[window._HASHQUERY.language],
  modal: {
    maskClosable: false
  }
})
Vue.mixin(globalMixin)
Vue.config.productionTip = false
Vue.prototype.NP = NP
Vue.prototype.serialize = serialize

Vue.prototype.formatDateStr = function (str) {
  var temp = ''
  if (str) {
    var arr = str.split(' ') || []
    temp = arr[0]
  }
  return temp
}

Vue.prototype.todo = function (str) {
  if(typeof(str)=='string'){
    Message.info(str + ' 功能开发中......')
  } else {
    Message.info('功能开发中......')
  }
}

Vue.prototype.$hasPermission = function (str){
  var flag = false
  if(localStorage.getItem('userAuth')){
    var userAuthArr = JSON.parse(localStorage.getItem('userAuth') || "[]")
    if(userAuthArr.length){
      userAuthArr.map((item)=> {
        if(item.code == str){
          flag = true
        }
      })
    }
  }
  return flag
}

Vue.filter('floatNum', function(num) {
  if (isNaN(+num)) return num
  if (typeof num !== 'string' || !num) num = (num || 0) + ''
  return /\./.test(num + '')
    ? /\.\d{2}/.test(num)
      ? num
      : num + '0'
    : num + '.00'
})

new Vue({
 router,
 store,
 render: h => h(App)
}).$mount("#app");
