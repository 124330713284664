export { default as validator } from "./validator";
export { default as downloader } from "./downloader";

//将对象转为url参数
export const serialize = obj => {
  let arr = [],
    i;
  for (i in obj) {
    arr.push(`${i}=${obj[i] != undefined ? obj[i] : ""}`);
  }
  return arr.join("&");
};

//获取传入对象中的某个key值
export const getPath = (obj, str) => {
  if (typeof obj !== "object") {
    console.warn("typeof obj is not object");
    return obj;
  }
  if (!str) return obj;
  let arr = str.split("."),
    curr = obj,
    i,
    len = arr.length;
  for (i = 0; i < len; i++) {
    if (!arr[i]) continue;
    curr = curr[arr[i]];
    if (typeof curr !== "object") break;
  }
  return curr;
};

//设置table单元格合并
export const setSpan = ({
  el,
  nullClass = ".td-null",
  spanClass = "td[class*=td-span]",
  type = "rowSpan"
}) => {
  let tbody = el.querySelectorAll("tbody.ivu-table-tbody");
  tbody.forEach(e => {
    e.querySelectorAll("td" + nullClass).forEach((td, i) => {
      td.parentNode.removeChild(td);
    });
    e.querySelectorAll(spanClass).forEach((td, i) => {
      td[type] = td.className.match(/\d+/)[0];
    });
  });
};

export const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  function(callback) {
    return window.setTimeout(callback, 1000 / 60);
  };

//将某个元素内容变速滚至顶部
export const backToTop = (selector = "html", step = 5, cb) => {
  let dom = document.querySelector(selector);
  let scroll = () => {
    // console.log(dom.scrollTop);
    dom.scrollTop -= dom.scrollTop / 10 + step * (dom.scrollTop / 100);
    dom.scrollTop > 0 ? requestAnimationFrame(scroll) : cb && cb();
  };
  scroll();
};

//获取滚动条宽度
let cacheBarSize;
export const getScrollBarSize = flag => {
  if (flag || cacheBarSize === undefined) {
    const outer = document.createElement("div");
    const outerStyle = outer.style;
    outerStyle.position = "absolute";
    outerStyle.top = 0;
    outerStyle.left = 0;
    outerStyle.zIndex = -1;
    outerStyle.visibility = "hidden";
    outerStyle.width = "100px";
    outerStyle.height = "100px";
    outerStyle.overflow = "scroll";
    outerStyle.pointerEvents = "none";
    document.body.appendChild(outer);
    cacheBarSize = outer.offsetWidth - outer.clientWidth;
    document.body.removeChild(outer);
  }
  return cacheBarSize;
};

// 导出为excel
export const exportExcel = table => {
  // mac os则使用xlsx插件导出，避免mac自带numbers显示不正常
  if (/macintosh|mac os x/i.test(navigator.userAgent)) {
    if (!window.XLSX) {
      Vue.prototype.$Message.warning("缺少xlsx依赖，请联系管理员( ･´ω`･ )");
      return;
    }
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet([
      table.columns.map(e => e.title),
      ...table.data.map(e =>
        table.columns.map(col => (e[col.key] != undefined ? e[col.key] : ""))
      )
    ]);
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    XLSX.writeFile(wb, table.name || "list.xls");
    return;
  }

  let uri = "data:application/vnd.ms-excel;base64,",
    template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta http-equiv="Content-Type"content="text/html;charset=UTF-8"><style>table{font-family:"Courier New";border-collapse:collapse;border:thin solid #000;}table th{border-right:thin solid #000;border-bottom:thin solid #000;font-size:15pt;}table td{border-right:thin solid #000;border-bottom:thin solid #000;font-size:13pt;}td.number-as-text{mso-number-format:"@";vnd.ms-excel.numberformat:@;}</style><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
    base64 = s => window.btoa(unescape(encodeURIComponent(s))),
    format = (s, c) => s.replace(/{(\w+)}/g, (m, p) => c[p]);

  // 处理table，组成html
  let ths = table.columns
    .map(e => {
      return `<th ${
        e.width || e.minWidth ? "width=" + (e.width || e.minWidth) : ""
      }>${e.title || ""}</th>`;
    })
    .join("");
  let tds;
  let trs = table.data
    .map(e => {
      tds = table.columns
        .map(
          col =>
            `<td align=${col.align || "left"} class=${col.className || ""}>${
              e[col.key] != undefined ? e[col.key] : ""
            }</td>`
        )
        .join("");
      return `<tr>${tds}</tr>`;
    })
    .join("");

  let ctx = {
    worksheet: table.name || "Worksheet",
    table: `<thead><tr>${ths}</tr></thead><tbody>${trs}</tbody>`
  };
  let file = new Blob([format(template, ctx)]);
  let url = URL.createObjectURL(file);
  let link = document.createElement("a");
  link.download = table.name || "order.xls";
  link.href = uri + base64(format(template, ctx));
  link.click();
  URL.revokeObjectURL(url);
};

export const weekMap = {
  0: "日",
  1: "一",
  2: "二",
  3: "三",
  4: "四",
  5: "五",
  6: "六"
};

// 让iview-table自带调整列支持调整未定义width的列
export const changeColWidth = (columns, newWidth, oldWidth, column, e) => {
  let col = columns[column._index];
  if (!col.__resized) {
    col.__resized = true;
    delete col.minWidth;
    delete col.width;
    Vue.set(col, "width", newWidth);
  }
};

// 获取元素距离页面顶部距离simple
export const getDiffToPageTop = el => {
  if (el.offsetParent) {
    return getDiffToPageTop(el.offsetParent) + el.offsetTop;
  }
  return el.offsetTop;
};
