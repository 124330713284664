var routes = [{
    path: "/",
    redirect: "/Index"
},  {
    path: "/Index",
    name: "Index",
    component: () => import(/* webpackChunkName: "Index" */ "../views/Index.vue")
},{
    path: "/ImgDetail",
    name: "ImgDetail",
    component: () => import(/* webpackChunkName: "ImgDetail" */ "../views/ImgDetail.vue")
},{
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "Login" */ "../views/Login.vue")
}]
export default routes

