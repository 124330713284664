<style lang="less">
.export-dropdown {
  &-btn {
    > .ivu-icon + span {
      display: none;
    }
    &.loading > .ivu-icon + span {
      display: initial;
    }
  }
}
</style>

<template>
  <Dropdown
    trigger="hover"
    :visible="show"
    placement="bottom-start"
    class="export-dropdown"
    @on-click="chooseAction"
  >
    <Button
      type="default"
      :icon="onlyIcon ? icon : undefined"
      class="export-dropdown-btn big-icon-btn"
      :class="{ loading: loading }"
      @click="toggle"
    >
      <template v-if="!onlyIcon || !icon">
        导出操作
        <Icon v-show="!loading" type="ios-arrow-down" />
      </template>
      <Icon
        v-show="loading"
        type="ios-loading"
        size="16"
        class="spin-icon-load"
      ></Icon>
    </Button>
    <template #list>
      <DropdownMenu>
        <DropdownItem v-if="!noAll" name="all" :disabled="loading"
          >导出全部</DropdownItem
        >
        <DropdownItem v-if="!noExcel" name="excel">导出当前页</DropdownItem>
        <!-- <DropdownItem v-if="!noTxt" name="txt">TXT</DropdownItem> -->
        <slot name="more"></slot>
      </DropdownMenu>
    </template>
  </Dropdown>
</template>

<script>
export default {
  name: "DownloadBtn",
  model: {
    prop: "visible"
  },
  props: {
    visible: Boolean,
    onlyIcon: Boolean,
    icon: {
      type: String,
      default: "md-download"
    },
    loading: Boolean,
    /* initial: {
      type: Boolean,
      default: true
    }, */
    noAll: Boolean,
    noExcel: Boolean
    /* noTxt: {
      type: Boolean,
      default: true
    } */
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    chooseAction(name) {
      this.$emit("on-click", name);
    },
    toggle() {
      this.show = !this.show;
      this.$emit("input", this.show);
    }
  },
  watch: {
    visible(val) {
      this.show = val;
    }
  }
};
</script>
