<style lang="less">
.shopcar-input > .ivu-input {
  text-align: center;
}
</style>

<template>
  <Input
    v-model.trim="count"
    :size="size"
    class="shopcar-input"
    @on-change="changeNum"
  >
    <template #prepend>
      <Button
        :size="size"
        icon="md-remove"
        :disabled="count <= min"
        @click="changeNum(-1)"
      ></Button>
    </template>
    <template #append>
      <Button
        :size="size"
        icon="md-add"
        :disabled="count >= max"
        @click="changeNum(1)"
      ></Button>
    </template>
  </Input>
</template>

<script>
export default {
  name: "Count",
  props: {
    value: Number,
    min: Number,
    max: Number,
    initial: {
      type: Number,
      default: null
    },
    size: {
      type: String,
      default: "small"
    },
    precision: Number
  },
  data() {
    return {
      count: ""
    };
  },
  methods: {
    changeNum(num) {
      let toFix;
      let val =
        this.count !== "" && !isNaN(this.count) ? +this.count : undefined;
      if (typeof num === "number") {
        val += num;
      }
      if (this.precision != null && typeof val === "number") {
        let valLeft = val.toString().match(/\.\d+/);
        // 如果当前输入值精度超过设定的精度，则将其固定为设定的精度
        if (valLeft && valLeft[0].slice(1).length > this.precision) {
          toFix = true;
          val = +val.toFixed(this.precision);
        }
      }
      val < this.min
        ? this.tick(this.min)
        : val > this.max
        ? this.tick(this.max)
        : isNaN(val)
        ? this.tick(this.initial)
        : this[toFix ? "tick" : "emit"](val);
    },
    tick(num) {
      this.$nextTick(() => {
        this.count = num;
        this.emit(this.count);
      });
    },
    emit(val) {
      this.$emit("input", val);
      this.$emit("change", val);
    }
  },
  watch: {
    value: {
      handler(val) {
        this.count = val;
      },
      immediate: true
    }
  }
};
</script>
