<template>
  <Modal
    v-model="show"
    :title="title"
    :width="width"
    :footer-hide="footerHide"
    @on-visible-change="changeModal"
  >
    <!-- <div> -->
    <slot></slot>
    <!-- </div> -->
    <FunTable
      ref="table"
      v-if="isLocal"
      :columns="columns"
      :data="data"
      :loading="loading"
      is-local
      :height="maxHeight"
    ></FunTable>
    <!-- :auto-max-height="false"
              :set-height.sync="setHeight" -->
    <FunTable
      ref="table"
      v-else
      :columns="columns"
      :data.sync="remoteData"
      :init-param="param"
      :get-param="getParam"
      :request-method="request"
      :refresh.sync="refreshFunc"
      :data-path="dataPath"
      :loading.sync="loadingData"
      :height="maxHeight"
    ></FunTable>
    <!-- :auto-max-height="false"
              :set-height.sync="setHeight" -->
  </Modal>
</template>

<script>
import FunTable from "@/components/FunTable.vue";

export default {
  name: "Log",
  components: {
    FunTable
  },
  model: {
    prop: "visible"
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "日志"
    },
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    param: {
      type: Object,
      default: () => {
        return {};
      }
    },
    request: Function,
    refresh: Function,
    width: {
      default: 65
    },
    dataPath: {
      type: String,
      default: "datas"
    },
    isLocal: Boolean,
    loading: Boolean,
    footerHide: {
      type: Boolean,
      default: true
    },
    getParam: Function
  },
  data() {
    return {
      show: false,
      loadingData: false,
      refreshFunc: undefined,
      setHeight: undefined,
      maxHeight: window.innerHeight / 2,
      remoteData: []
    };
  },
  methods: {
    changeModal(visible) {
      this.$emit("input", visible);
      !visible &&
        setTimeout(() => {
          this.$emit("update:data", []);
          this.$emit("reset");
        }, 200);
    }
  },
  watch: {
    visible(val) {
      this.show = val;
      if (!val) return;
      !this.isLocal && this.$nextTick(this.refreshFunc);
      /*!this.hasSetHeight && this.setHeight();
      if (!this.hasSetHeight) this.hasSetHeight = true;*/
    },
    loadingData(val) {
      this.$emit("update:loading", val);
    },
    refreshFunc(val) {
      this.$emit("update:refresh", val);
    },
    remoteData(val) {
      this.$emit("update:data", val);
    }
  }
};
</script>
