<template>
  <Select
    v-bind="$attrs"
    v-on="$listeners"
    v-model="code"
    :multiple="multiple"
    @on-change="changeCode"
  >
    <template v-if="!once">
      <Option
        v-for="(item, index) in codes"
        :key="index"
        :value="item[codeMap.value]"
        :label="formatLabel && formatLabel(item)"
      >
        <slot v-bind="item"
          >{{ item[codeMap.label] }}({{ item[codeMap.value] }})</slot
        >
      </Option>
    </template>
    <template v-if="once && codes.length">
      <Option
        v-for="(item, index) in codes"
        v-once
        :key="index"
        :value="item[codeMap.value]"
        :label="formatLabel && formatLabel(item)"
      >
        <slot v-bind="item"
          >{{ item[codeMap.label] }}({{ item[codeMap.value] }})</slot
        >
      </Option>
    </template>
  </Select>
</template>

<script>
export default {
  name: "CodeSelect",
  inheritAttrs: false,
  props: {
    value: [String, Array],
    list: Array,
    multiple: Boolean,
    codeKey: {
      type: String,
      default: "code"
    },
    descKey: {
      type: String,
      default: "descript"
    },
    keyMap: Object,
    method: Function,
    refresh: Function,
    process: Function,
    // pretreatment: Function,
    param: Object,
    chosen: [Object, Array],
    once: Boolean,
    autoGet: {
      type: Boolean,
      default: true
    },
    getParam: Function,
    formatLabel: Function
  },
  data() {
    return {
      code: "",
      codes: []
    };
  },
  computed: {
    codeMap() {
      return {
        value: this.codeKey,
        label: this.descKey,
        ...this.keyMap
      };
    }
  },
  methods: {
    async getList() {
      if (typeof this.method !== "function") {
        console.warn("typeof method isn't function");
        return;
      }
      let res = await this.method(
        typeof this.getParam === "function" ? this.getParam() : this.param
      );
      if (res && typeof this.process === "function") {
        let processed = this.process(res);
        if (processed != undefined) {
          res = processed;
        }
      }
      if (!res) return;
      this.codes = res;
      this.$emit("update:list", res);
      this.code && this.changeCode(this.code);
    },
    changeCode(val) {
      this.changeByEmit = true;
      this.$nextTick(() => {
        this.changeByEmit = false;
      });
      this.$emit("input", val || "");
      this.$emit(
        "update:chosen",
        !this.multiple
          ? this.codes.find(e => e[this.codeMap.value] === val) || {}
          : this.codes.filter(e => this.code.includes(e[this.codeMap.value]))
      );
    }
  },
  created() {
    // window.cs = this;
    this.code = this.value || "";
    this.$emit("update:refresh", this.getList);
    this.autoGet && this.getList();
  },
  mounted() {
    // console.log(this.$attrs);
  },
  watch: {
    value(val) {
      if (this.changeByEmit) return;
      this.code = val || "";
      this.code && this.changeCode(this.code);
    },
    list(val) {
      this.codes = val || [];
    }
  }
};
</script>
