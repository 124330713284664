<template>
  <Row justify="space-between" align="middle">
    <Col>
      <slot name="other"></slot>
    </Col>
    <Col class="flex">
      <slot name="action"></slot>
      <template v-if="!cancelRight">
        <Button v-bind="cancelProps" @click="toCancel">{{ cancelText }}</Button>
        <Button
          v-bind="confirmProps"
          type="primary"
          @click="$emit('on-confirm')"
          >{{ confirmText }}</Button
        >
      </template>
      <template v-else>
        <Button
          v-bind="confirmProps"
          type="primary"
          @click="$emit('on-confirm')"
          >{{ confirmText }}</Button
        >
        <Button v-bind="cancelProps" @click="toCancel">{{ cancelText }}</Button>
      </template>
    </Col>
  </Row>
</template>

<script>
export default {
  name: "ModalFooter",
  inheritAttrs: false,
  props: {
    value: Boolean,
    confirmText: {
      type: String,
      default: "确定"
    },
    cancelText: {
      type: String,
      default: "取消"
    },
    confirm: Object,
    confirmLoading: Boolean,
    confirmDisabled: Boolean,
    cancel: Object,
    cancelLoading: Boolean,
    cancelDisabled: Boolean,
    cancelType: {
      type: String,
      default: "text"
    },
    cancelRight: Boolean
  },
  data() {
    return {};
  },
  computed: {
    confirmProps() {
      return {
        disabled: this.confirmDisabled,
        loading: this.confirmLoading,
        ...this.confirm
      };
    },
    cancelProps() {
      return {
        disabled: this.cancelDisabled,
        loading: this.cancelLoading,
        type: this.cancelType,
        ...this.cancel
      };
    }
  },
  methods: {
    toCancel() {
      this.$listeners["on-cancel"]
        ? this.$emit("on-cancel")
        : this.$emit("input", false);
    }
  }
};
</script>
