<style lang="less">
.toggle-column {
  text-align: left;
}
.toggle-column-list {
  max-height: 400px;
  max-height: 70vh;
  overflow: auto;
  user-select: none;
}
.toggle-btn > .ivu-icon {
  line-height: 1;
  font-size: 16px;

  + span {
    display: none;
  }
}
</style>

<template>
  <Dropdown
    trigger="custom"
    :visible="visible"
    placement="bottom-start"
    class="toggle-column"
  >
    <Button
      type="default"
      :icon="onlyIcon ? icon : undefined"
      class="toggle-btn"
      @mouseover.native="toggle(true)"
      @mouseout.native="toggle(false)"
    >
      <template v-if="!onlyIcon || !icon"
        >选择列 <Icon type="ios-arrow-down"
      /></template>
      <s></s>
    </Button>
    <template #list>
      <DropdownMenu
        class="toggle-column-list"
        @mouseenter.native="toggle(true)"
        @mouseleave.native="toggle(false)"
      >
        <DropdownItem
          v-for="(item, index) in checkList"
          :key="index"
          :disabled="!item._switchable"
        >
          <Checkbox
            v-model="item._visible"
            :disabled="!item._switchable"
            @on-change="change(item, index)"
            >{{ item.title }}</Checkbox
          >
        </DropdownItem>
      </DropdownMenu>
    </template>
  </Dropdown>
</template>

<script>
export default {
  name: "ToggleColumn",
  model: {
    prop: "columns",
    event: "input"
  },
  props: {
    columns: {
      type: Array
    },
    onlyIcon: Boolean,
    icon: {
      type: String,
      default: "md-menu"
    }
  },
  data() {
    return {
      visible: false,
      backup: [],
      checkList: []
    };
  },
  computed: {},
  methods: {
    change(item, index) {
      // console.log(item.key, item._visible);
      this.backup[index]._visible = item._visible;
      this.emit();
      let showns = this.checkList.filter(e => e._visible);
      showns.length === 1
        ? (showns[0]._switchable = false)
        : showns.forEach(e => {
            if (e.initSwitchable && !e._switchable) e._switchable = true;
          });
      this.$emit("change", this.backup[index]);
    },
    toggle(flag) {
      clearTimeout(this.toToggle);
      this.toToggle = setTimeout(() => {
        // console.log("toggle");
        this.visible = flag;
      }, 200);
    },
    setColumns() {
      this.columns.forEach(e => {
        if (!e.hasOwnProperty("_visible")) {
          e._visible = true;
        }
        if (!e.hasOwnProperty("_switchable")) {
          e._switchable = true;
        }
        this.backup.push(e);
        this.checkList.push({
          title: e.title || "",
          // key: e.key,
          initSwitchable: e._switchable,
          _switchable: e._switchable,
          _visible: e._visible
        });
      });
      this.emit();
    },
    emit() {
      this.emitFromSelf = true;
      this.$emit(
        "input",
        this.backup.filter(e => e._visible)
      );
      this.$nextTick(() => {
        this.emitFromSelf = false;
      });
    }
  },
  mounted() {
    this.setColumns();
  },
  watch: {
    columns(val) {
      if (!this.emitFromSelf) {
        this.backup = [];
        this.checkList = [];
        this.setColumns();
      }
    }
  }
};
</script>
