<template>
  <div class="page-head">
    <div class="title" @click="goBack">
      <Icon v-if="showBack" type="ios-arrow-back" size="25" color="#333"/>
      {{ title || '' }} <span v-if="pcDescript" class="pc-descript">{{ pcDescript || '' }}</span>
    </div>

    <div class="right-part">

      <Poptip title="" content="content">
        <div class="basic-info">
          <Icon type="ios-contact" size="30"/>
          <span class="name">{{ userName }}</span>
        </div>

        <div class="basic-content" slot="content">
          <Button class="spe-btn" @click="openModal">修改密码</Button>
        </div>
      </Poptip>


      <div class="right-btn" @click="doLogout">
        <Icon type="ios-log-out" size="25" color="#fff"/>
        <span class="txt">退出</span>
      </div>
    </div>

    <Modal
        v-model="showModal"
        title="修改密码"
        :loading="setNumberModal">
      <Form
          style="padding: 30px"
          ref="editForm"
          :label-width="100">
        <FormItem label="旧密码：">
          <Input style="width: 200px" size="large" v-model="editForm.oldPassword"/>
        </FormItem>
        <FormItem label="新密码：">
          <Input style="width: 200px" size="large" v-model="editForm.newPassword"/>
        </FormItem>
        <FormItem label="确认新密码：">
          <Input style="width: 200px" size="large" v-model="editForm.newPassword2"/>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" @click="closeModal">取消</Button>
        <Button type="primary" @click="setValue">确定</Button>
      </div>
    </Modal>

  </div>
</template>

<script>
export default {
  name: "PageHead",
  props: {
    title: '',
    showBack: false,
  },
  data() {
    return {
      pcDescript: '',
      userName: localStorage.getItem('userName') || '',

      showModal: false,
      setNumberModal: true,
      editForm: {
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
      }
    };
  },
  mounted() {
    // this.getPccodeList()
  },
  methods: {
    openModal() {
      var self = this
      self.resetModal()
      self.setNumberModal = true
      self.showModal = true
    },
    closeModal() {
      var self = this
      self.resetModal()
      self.setNumberModal = false
      self.showModal = false
    },

    resetModal() {
      var self = this
      self.$refs['editForm'].resetFields();
      self.editForm = {
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
      }
    },

    setValue() {
      var self = this
      var flag = true
      var editForm = this.editForm || {}
      if (!editForm.oldPassword) {
        self.$Message.warning('请输入旧密码')
        flag = false
        return
      }
      if (!editForm.newPassword) {
        self.$Message.warning('请输入新密码')
        flag = false
        return
      }
      if (!editForm.newPassword2) {
        self.$Message.warning('请再次输入新密码')
        flag = false
        return
      }
      if (editForm.newPassword != editForm.newPassword2) {
        self.$Message.warning('两次输入的新密码不一致，请检查')
        flag = false
        return
      }
      if (editForm.oldPassword == editForm.newPassword) {
        self.$Message.warning('原密码与新密码输入一致，密码没有改变')
        flag = false
        return
      }

      if (flag) {
        self.setNumberModal = true
        self.doSave()
      }

    },

    doSave() {
      const self = this
      var editForm = this.editForm || {}
      this.AXIOS.post('/pos/router?method=updateUserPassword', {
        method: 'updateUserPassword',
        oldPassword: editForm.oldPassword,
        newPassword: editForm.newPassword,
      }).then((res) => {
        self.$Message.success('密码修改成功')
        localStorage.removeItem('token')
        self.$router.replace('/login')
      }).finally(() => {
        setTimeout(() => {
          self.setNumberModal = false
        }, 1000)
      })
    },

    getPccodeList() {
      const self = this
      var row = self.currentRow || {}
      this.AXIOS.post('/pos/router?method=getGBPccodesByUserCode', {
        v: '1.0',
        method: 'getGBPccodesByUserCode',
      }).then((res) => {
        if (res.length) {
          var firstItem = res[0] || {}
          this.pcDescript = firstItem.descript || ''
        }
      }).finally(() => {
      })
    },


    doLogout() {
      this.$Modal.confirm({
        title: '提示',
        content: '确定退出登录吗？',
        onOk: () => {
          localStorage.removeItem('token')
          this.$router.replace('/login')
        },
        onCancel: () => {
        }
      });
    },
    goBack() {
      if (this.showBack) {
        this.$router.back()
      }
    },
  }
};
</script>
<style lang="less">
.page-head {
  background: rgb(1, 7, 30);
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  color: #fff;

  .pc-descript {
    display: inline-block;
    font-size: 13px;
    padding: 3px 20px;
    border-radius: 5px;
    background: #c2e9f7;
    margin-left: 20px;
  }

  .title {
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  .right-part {
    display: flex;
    align-items: center;

    .basic-info {
      margin-right: 30px;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .name {
        padding-left: 5px;
      }
    }

    .basic-content {
      .spe-btn {
        width: 100%;
      }
    }

    .right-btn {
      font-size: 14px;
      color: #fff;
      display: flex;
      align-items: center;
      cursor: pointer;

      .txt {
        padding-left: 5px;
      }
    }
  }

}
</style>
