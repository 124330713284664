<template>
  <Modal
      v-model="show"
      title="日志"
      @on-visible-change="changeModal"
      :width="$store.state.modalWidth">
    <Table maxHeight="500"
           border
           :loading="loading"
           :columns="column"
           :data="listData"
                size="large"
        :highlight-row="true">
      <template slot-scope="{ row, index }" slot="bizDate">
        {{ formatDateStr(row.bizDate) }}
      </template>
    </Table>
<!--    <p style="text-align:right; margin-top: 10px;">记录总数 {{ total }} 组</p>-->
    <template #footer>
      <Button @click="show=false">取消</Button>
    </template>
  </Modal>
</template>

<script>
export default {
  name: "EditLogModal",
  components: {},
  model: {
    prop: "visible"
  },
  props: {
    visible: Boolean,
    url: {
      default: 'hotelopen.basic.getEntityOperationLog'
    },
    entityName: '',
    entityId: '',
  },
  data() {
    return {
      show: false, //是否显示
      loading: false, //日志列表加载状态
      column: [{
        title: '处理时间', key: 'createDatetime'
      }, {
        title: '操作人', key: 'createUser'
      },{
        title: '变更字段', key: 'columnName',
      }, {
        title: '变更前', key: 'oldValue'
      }, {
        title: '变更后', key: 'newValue'
      }, ],
      listData: [],
      total: 0,
      firstResult: 0,
      maxResult: 50,
    };
  },
  methods: {
    changeModal(visible) {
      this.$emit("input", visible); // 通过$emit('input')将双向绑定的 prop 值改为与 当前组件data中的 show 一样的值
    },
    getList() {
      const self = this
      var row = this.row || {}
      this.loading = true
      this.AXIOS.post(this.url, {
        entityName: this.entityName || '',
        entityId: this.entityId || '',
      }).then(res => {
        self.listData = res.rows || []
        self.total = res.total || 0
      }).finally(res => {
        self.loading = false
      })
    },
  },
  watch: {
    visible(val) {
      this.show = val;
      if(val){
        this.getList()
      }
    }
  }
};
</script>
