import Vue from "vue";
import Vuex from "vuex";
import $T from "@/assets/locale";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    isAdmin: false,
    isUnion: false,
    isGroup: false,
    locale: localStorage.locale || "zh-CN",
    $T,
    modalTableHeight: window.innerHeight / 2 + 60, // 模态框内table高度
    modalWidth:
      window.innerWidth > 1600 ? 55 : window.innerWidth > 1366 ? 65 : 75 // 模态框三种宽度
  },
  getters: {},
  mutations: {
    setUserInfo(state, val) {
      state.userInfo = val;
      state.isUnion = val.userType === "UNION";
      state.isGroup = val.userType === "GROUP";
      state.isAdmin = val.userType === "GC";
    },
    setLanguage(state, val) {
      state.locale = val;
    },
    setLocale(state, val) {
      state.$T = val;
    }
  },
  actions: {}
});
