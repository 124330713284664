<style lang="less">
.togglebtn {
  padding-left: 10px;
  padding-right: 10px;
  word-spacing: 2px;

  &:hover {
    .arrow {
      &::before,
      &::after {
        border-color: #57a3f3;
      }
    }
  }
  /*过滤按钮激活状态*/
  &.active {
    .arrow {
      &::before,
      &::after {
        border-color: #2b85e4;
        transform-origin: 50% 75%;
        transform: rotateZ(135deg) skewX(10deg) skewY(10deg);
      }
    }
  }

  .arrow {
    display: inline-block;
    position: relative;
    width: 12px;
    height: 12px;
    vertical-align: text-top;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 6px;
      height: 6px;
      margin-left: -25%;
      border: 1px solid #999;
      border-top: none;
      border-right: none;
      transition: border-color 0.2s ease, transform 0.2s ease;
      transform: rotateZ(-45deg) skewX(10deg) skewY(10deg);
    }

    &:after {
      top: 5px;
    }
  }
}
</style>

<template>
  <Button
    type="default"
    class="togglebtn"
    :class="{ active: expanded }"
    :size="size"
    @click="toggle"
    >{{ text }} <i class="arrow"></i
  ></Button>
</template>

<script>
export default {
  name: "ToggleBtn",
  props: {
    value: Boolean,
    size: String
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    text() {
      return this.expanded ? "隐藏" : "过滤";
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
      this.$emit("input", this.expanded);
      this.$emit("on-change", this.expanded);
    }
  },
  watch: {
    value(val) {
      this.expanded = val;
    }
  }
};
</script>
