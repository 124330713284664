<style lang="less">
@border: 1px solid #f5f5f5;
@width: 14.2857%;

.bgColor(@color) {
  border-color: @color;
  background-color: @color;
}

.calendar {
  position: relative;
  color: #333;

  &- {
    &title {
      flex-basis: @width;
      max-width: @width;
      padding: 10px 0;
      line-height: 1;
      text-align: center;
      background: #efefef;

      &.weekend {
        color: #fff;
        background: coral;
      }
    }
    &item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: @width;
      max-width: @width;
      height: 100px;
      padding: 5px;
      border-bottom: @border;
      border-left: @border;
      transition: border-color 0.2s ease-out, background-color 0.2s ease-out;
      // font-size: 14px;

      &:nth-child(7n) {
        border-right: @border;
      }
      &.outer:not(.in-range) {
        color: #aaa;
      }
      &.in-range {
        .bgColor(#e6eafd);
      }
      &.range-start,
      &.range-end {
        .bgColor(#b9c6ff);
      }
    }
    &item_today {
      background-color: #f2faff;
    }
  }
}
</style>

<template>
  <div class="calendar">
    <Row>
      <Col
        v-for="(day, index) in weeks"
        :key="index"
        span="4"
        :class="[
          'calendar-title',
          { weekend: offDay.includes(day.week) },
          weekClass && weekClass(day, index)
        ]"
        >{{ day.title }}</Col
      >
    </Row>
    <Row>
      <Col
        v-for="(item, index) in list"
        :key="index"
        span="4"
        :class="[
          'calendar-item',
          { 'calendar-item_today': item._isToday },
          { outer: item._isOuter },
          { 'range-start': item === rangStart },
          { 'in-range': item._inRange },
          { 'range-end': item === rangeEnd },
          dayClass && dayClass(item, index),
          item.className
        ]"
        @click.native="selectDate(item, index)"
        @dblclick.native="dblClick(item, index)"
        @mouseenter.native="hoverDate(item, index)"
      >
        <slot name="day" :day="item" :index="index">
          {{ item._text }}
        </slot>
      </Col>
    </Row>

    <Spin v-show="loading" fix></Spin>
  </div>
</template>

<script>
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
// import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
// import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { weekMap } from "@/utils";

dayjs.extend(isBetween);
// dayjs.extend(isSameOrBefore);
// dayjs.extend(isSameOrAfter);

export default {
  name: "Calendar",
  props: {
    date: Date,
    dates: Array,
    startWeek: {
      type: Number,
      default: 0,
      validator(val) {
        return val > -1 && val < 7;
      }
    },
    offDay: {
      type: Array,
      default: () => [5, 6]
    },
    dateKey: {
      type: String,
      default: "date"
    },
    weekClass: Function,
    dayClass: Function,
    loading: Boolean,
    textFormat: {
      type: String,
      default: "YYYY-MM-DD"
    },
    isDateMode: Boolean, // 是否日期模式。该模式下将不会按照传统日历模式将每月1号放在第一行，而是将指定日期放在第一行，并将整个42格限定为起止范围，故可能不会显示完整的某月上下范围
    hasRange: Boolean, // 是否开启范围选择
    outerInRange: Boolean, // 非当前有效时间范围内的日期是否可被选入范围
    clearable: {
      // 选择一个范围后，再选择时是否先将已选范围清除，即是否可清除已选范围
      type: Boolean,
      default: true
    },
    range: Array // 已选范围
  },
  data() {
    return {
      list: [],
      rangStart: undefined,
      rangeEnd: undefined
    };
  },
  computed: {
    weekAt() {
      let startWeek = Math.floor(this.startWeek);
      if (isNaN(startWeek) || startWeek < 0 || startWeek > 6) {
        startWeek = 0;
      }
      return startWeek;
    },
    weeks() {
      let arr = [],
        max = this.weekAt + 7,
        week;
      for (let i = this.weekAt; i < max; i++) {
        week = i % 7;
        arr.push({
          title: weekMap[week],
          week
        });
      }
      return arr;
    }
  },
  methods: {
    // 计算第一格及最后一格对应日期
    getRange(date) {
      let mDate = dayjs(
          date ||
            this.date ||
            (this.dates && this.dates[0] && this.dates[0][this.dateKey])
        ),
        start,
        end,
        firstDay;

      if (this.isDateMode) {
        start = mDate;
      } else {
        start = mDate.startOf("month");
        end = mDate.endOf("month");
      }
      firstDay = start.day();

      // 若当前开始星期不是星期天且1号是星期天，则需要将星期天值改为7
      if (this.weekAt !== 0 && firstDay === 0) {
        firstDay = 7;
      }
      mDate = start.subtract(firstDay - this.weekAt, "day"); // 第一格日期
      if (this.isDateMode) {
        end = mDate.add(41, "day");
      }

      return {
        beginDate: mDate.format("YYYY-MM-DD"),
        endDate: end.format("YYYY-MM-DD"),
        extra: [mDate, this.isDateMode ? mDate : start, end]
      };
    },
    // 根据日期渲染对应月份
    renderByDate() {
      if (this.hasRange) {
        this.rangStart = this.rangeEnd = null;
      }
      let {
        extra: [mDate, start, end]
      } = this.getRange();
      let i,
        eachDay,
        dates = this.dates && this.dates[0] ? this.dates.slice() : [],
        now = new Date(),
        sameDate;

      this.list = [];
      for (i = 0; i < 42; i++) {
        eachDay = mDate.add(i, "day");
        sameDate = dates.find(d => eachDay.isSame(d[this.dateKey], "day"));
        this.list.push({
          _date: eachDay.toDate(),
          _text: eachDay.format(this.textFormat),
          _isOuter: !eachDay.isBetween(start, end, "day", "[]"),
          _isToday: eachDay.isSame(now, "day"),
          // _rangeStart: undefined,
          // _rangeEnd: undefined,
          _inRange: undefined,
          ...sameDate
        });
      }
    },
    selectDate(item, index) {
      // console.log(item, index);

      if (this.hasRange && (!item._isOuter || this.outerInRange)) {
        // 选择开头
        if (!this.rangStart) {
          this.rangStart = item;
          item._inRange = true;
        } else if (!this.rangeEnd) {
          // 选择结尾
          if (item._date < this.rangStart._date) {
            let temp = this.rangStart;
            this.rangStart = item;
            item = temp;
          }
          this.rangeEnd = item;
          item._inRange = true;
        } else {
          // 选过范围则重置选择状态
          this.rangStart = this.rangeEnd = null;
          this.list.forEach(e => {
            e._inRange = false;
          });
          if (!this.clearable) {
            this.rangStart = item;
          }
        }

        this.$emit(
          "select-range",
          [this.rangStart, this.rangeEnd],
          this.list.slice(
            this.list.indexOf(this.rangStart),
            this.list.indexOf(this.rangeEnd) + 1
          )
        );
        this.rangedByEmit = true;
        this.$nextTick(() => {
          this.rangedByEmit = false;
        });
        this.$emit("update:range", [this.rangStart, this.rangeEnd]);
      }
      this.$emit("click-day", item, index);
    },
    dblClick(item, index) {
      // console.log(item, index);
      this.$emit("dblclick-day", item, index);
    },
    hoverDate(item, index) {
      if (
        !this.hasRange ||
        !this.rangStart ||
        this.rangeEnd ||
        (item._isOuter && !this.outerInRange)
      )
        return;
      let each,
        startIndex = this.list.indexOf(this.rangStart);
      if (index < startIndex) {
        let temp = startIndex;
        startIndex = index;
        index = temp;
      }
      this.list.forEach((e, i) => {
        each = dayjs(e._date);
        e._inRange = i >= startIndex && i <= index;
        // e._inRange = each.isSameOrAfter(this.rangStart, 'day') && each.isSameOrBefore(this.rangeEnd, 'day')
      });
    }
  },
  created() {
    this.renderByDate();
  },
  watch: {
    date(val) {
      !this.dates && val instanceof Date && this.renderByDate();
    },
    dates(val) {
      val && this.renderByDate();
    },
    startWeek(val) {
      // console.log(val);
      this.renderByDate();
    },
    range(val) {
      if (!this.hasRange || this.rangedByEmit) return;
      if (!val || !val.length) {
        this.rangStart = this.rangeEnd = null;
        this.list.forEach(e => {
          e._inRange = false;
        });
        return;
      }
      if (val[0] && val[1]) {
        if (dayjs(val[0]).isAfter(val[1])) {
          let temp = val[1];
          val[1] = val[0];
          val[0] = temp;
        }
        let each, startIndex, endIndex;
        for (let i = 0, len = this.list.length; i < len; i++) {
          each = dayjs(this.list[i]._date);
          if (each.isSame(val[0], "day")) {
            this.rangStart = this.list[i];
            startIndex = i;
          }
          if (each.isSame(val[1], "day")) {
            this.rangeEnd = this.list[i];
            endIndex = i;
            break;
          }
        }
        this.list.forEach((e, i) => {
          e._inRange = i >= startIndex && i <= endIndex;
        });
      }
    }
  }
};
</script>
