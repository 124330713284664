<style lang="less"></style>
<template>
  <Select filterable clearable v-model="language" @on-change="changeLang">
    <Option value="zh-CN">中文</Option>
    <Option value="en-US">English</Option>
  </Select>
</template>
<script>
export default {
  name: "Lang",
  data() {
    return {
      language: "zh-CN"
    };
  },
  methods: {
    changeLang(lang) {
      let id = "lang",
        langEl = document.getElementById(id);
      langEl.parentNode.removeChild(langEl);
      langEl = document.createElement("script");
      langEl.id = id;
      langEl.src = lang ? `./${lang}.js` : "";
      document.head.appendChild(langEl);
      localStorage.language = lang;
    }
  }
};
</script>
