window.ENUM = {
  bankArr: [{
    "code": "PUN",
    "descript": "银联商务"
  }, {
    "code": "ICBC",
    "descript": "工商银行"
  }, {
    "code": "NG",
    "descript": "新大陆"
  }, {
    "code": "CCB",
    "descript": "建设银行"
  }, {
    "code": "BCM",
    "descript": "交通银行"
  }, {
    "code": "BOC",
    "descript": "中国银行"
  }, {
    "code": "CIB",
    "descript": "兴业银行"
  }],
  // 代码类型字典
  codeTypeList: [{
    value: "1",
    label: "代码"
  }, {
    value: "2",
    label: "类型"
  }],
  // 四舍五入字典
  roundTailList: [{
    value: "NORMAL",
    label: "四舍五入"
  }, {
    value: "",
    label: "无"
  }],
  // 四舍五入size字典
  roundSizeList: [{
    value: "0",
    label: "0"
  }, {
    value: "0.1",
    label: "0.1"
  }, {
    value: "",
    label: "无"
  }],
  // 操作类型
  operationTypeList: {
    'E': '发布',
    'A': '设置'
  }

  // targetType: {
  //   'platform': '平台端',
  //   'asset': '资产端',
  //   'capital': '资金端',
  // },
  // sysUserStatus: {
  //     freeze: '冻结',
  //     normal: '正常',
  //     cancel: '注销'
  // },
  // checkStatus: {
  //     saved: '存盘',
  //     wait_audit: '待审核',
  //     audited: '审核通过',
  //     unaudited: '审核不通过'
  // },
  // checkStatusMap: [
  //     {value: 'saved', label: '存盘'},
  //     {value: 'wait_audit', label: '待审核'},
  //     {value: 'audited', label: '审核通过'},
  //     {value: 'unaudited', label: '审核不通过'},
  // ],
  // obCheckStatus: {
  //     saved: '存盘',
  //     wait_audit: '待审核',
  //     audited: '审核通过',
  //     unaudited: '审核不通过',
  //     discard: '废弃'
  // },
  // obStatusTypeMap: [
  //     {value: 'saved', label: '存盘'},
  //     {value: 'wait_audit', label: '待审核'},
  //     {value: 'audited', label: '审核通过'},
  //     {value: 'unaudited', label: '审核不通过'},
  //     {value: 'discard', label: '废弃'}
  // ],
  // platformObStatusTypeMap: [
  //     {value: 'wait_audit', label: '待审核'},
  //     {value: 'audited', label: '审核通过'},
  //     {value: 'unaudited', label: '审核不通过'},
  //     {value: 'discard', label: '废弃'}
  // ],
  // menuType: {
  //     'platform': 'platform',
  //     'asset': 'asset',
  //     'capital': 'capital'
  // },
  // targetType: [{
  //     value: 'platform',
  //     label: 'platform'
  // }, {
  //     value: 'asset',
  //     label: 'asset'
  // }, {
  //     value: 'capital',
  //     label: 'capital'
  // }],
  // sex: {
  //     'female': '女',
  //     'male': '男'
  // },
  // borrowerType: {
  //     'personal': '个人',
  //     'enterprise': '企业'
  // }
}
