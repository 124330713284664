<template>
  <Modal
      v-model="show"
      title="请选择酒店"
      :scrollable="true"
      width="600"
      height="600"
      :footer-hide="true"
      @on-visible-change="changeModal"
      :mask-closable="false">
    <hotel-tree-select
        show-key="descript"
        model-key="id"
        :visible-contrl="hotelSelectShow"
        hotel-list-api="/extapi/v1/coderatecode/hotel/list"
        :hotel-list-params="{stas: stas}"
        @hotelTreeSelectQuit="hotelTreeSelectQuit"
        @hotelTreeSelectCommit="chooseHotelCommit"
    ></hotel-tree-select>
  </Modal>
</template>
<script>
export default {
  name: "HotelTreeSelectModal",
  model: {
    prop: "visible"
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    showAllHotels: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hotelSelectShow: false,
      show: false, //是否显示
      stas: this.showAllHotels ?  [] : ['I'],
    }
  },
  watch: {
    'visible'(val) {
      this.show = val;
      this.hotelSelectShow = val;
    }
  },
  methods: {
    hotelTreeSelectQuit(){
      this.show = false
      this.hotelSelectShow = false
    },
    changeModal(visible) {
      this.$emit("input", visible); // 通过$emit('input')将双向绑定的 prop 值改为与 当前组件data中的 show 一样的值
    },
    chooseHotelCommit(hotelForm,choosenRow) {
      this.show = false
      this.hotelSelectShow = false
      var codes = _.pluck(choosenRow, 'code') || []
      var codeStr = codes.join(',')
      this.$emit("confirm", hotelForm, choosenRow, codeStr)
    },
  },
}
</script>
<style lang="less" scoped>
.choose_hotel_table{
 /deep/ .ivu-table-cell-slot{
   display: inline-block;
   vertical-align: middle;
 }
}
</style>
