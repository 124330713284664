<template>
  <div>
    <Input
      v-model="content"
      :placeholder="placeholderString"
      :readonly="inputReadOnly"
      @on-focus="blurInput"
    >
      <Button
        slot="append"
        icon="ios-arrow-down"
        :disabled="clickDisabledControl"
        @click="click()"
      ></Button>
    </Input>
    <Modal
      :key="getListApi"
      v-model="controlShow"
      :title="title"
      :scrollable="true"
      :closable="false"
      :width="$store.state.modalWidth"
      height="700"
      :mask-closable="false"
    >
      <div>
        <div class="search-bar">
          <Input v-model="searchText" placeholder="查询" style="padding-bottom: 15px;" @on-change="changeSearchText"></Input>
        </div>
        <Table
          :key="getListApi"
          ref="table"
          border
          max-height="500"
          :columns="columnsArr"
          :data="tableData"
          :loading="loading"
          @on-selection-change="selectChange"
          @on-select="selectSingleChange"
          @on-row-click="clickRow"
        >
          <template slot="descriptEn" slot-scope="{ row }">
            {{ row.descriptEn || row.descript_en || '' }}
          </template>
        </Table>
      </div>
      <div slot="footer">
        <div style="display: flex; justify-content: space-between">
          <div>
            <!-- 单选没有反选和全选 -->
            <Button v-if="!(isSingleSelect === true)" @click="invertSelection()">反选</Button>
            <Button v-if="!(isSingleSelect === true)" @click="handleSelectAll(true)">全选</Button>
            <Button @click="handleSelectAll(false)">清空</Button>
          </div>
          <div>
            <Button @click="cancel()">取消</Button>
            <Button type="primary" @click="confirm()">确定</Button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  name: "InputListSelect",
  props: {
    inputReadOnly: {
      type: Boolean,
      default: true
    },
    title: { // 对话框的title
      type: String,
      default: 'title'
    },
    placeholderString: { // input提示
      type: String,
      default: '请输入'
    },
    getListApi: { // 获取列表的接口
      type: String,
      default: null
    },
    getListParams: { // 获取列表的参数
      type: Object,
      default: () => ({})
    },
    columnsArr: { // table列数组
      type: Array,
      default: () => {
        return [
          {
            type: "selection",
            width: 60,
            align: "center"
          },
          {
            title: "代码",
            key: "code"
          },
          {
            title: "描述",
            key: "descript"
          },
          {
            title: "英文描述",
            key: "descriptEn",
            slot: 'descriptEn'
          }
        ]
      }
    },
    selectKey: { // 根据此key返回给数组列表key的数组
      type: String,
      default: 'code'
    },
    showKey: { // input中显示的内容key
      type: String,
      default: 'code'
    },
    axiosRes: { // 当得到res时由于结果列表不一定放在resultInfo里还是resultInfo的某个属性里，所以设置下
      type: String,
      default: null
    },
    isSingleSelect: {
      type: Boolean,
      default: false
    },
    keyIsNumber: {
      type: Boolean,
      default: false
    },
    defaultValue: { // 默认值，都是以逗号隔开的字符串，比如 amenities: "AMR,BRS,SFT"
      type: String,
      default: null
    },
    alwaysShowDefault: {
      type: Boolean,
      default: false
    },
    clickDisabledControl: { // 控制input右侧按钮的disabled
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      initialized: false,
      content: '', // 存放显示的内容
      selectKeyString: '', // 选中的值
      controlShow: false,
      tableData: [],
      selectedArr: [],
      selectSingleFlag: true,
      // 搜索条
      searchText: '',
      originData: [], // 原始数据
      loading: false
    }
  },
  watch: {
    "defaultValue"(val) {
      if (val) {
        this.putDefaultValue()
      }
    }
  },
  created() {
    this.putDefaultValue() // 这里不太确定要怎么改动
  },
  methods: {
    clearValue() {
      this.content = ''
      this.selectedArr = []
      this.selectKeyString = ''
    },
    getContent() {
      return this.content
    },
    blurInput() {
      let commitResult = []
      if (this.selectedArr.length !== 0) {
        this.selectedArr.map(item => {
          commitResult.push(item[this.selectKey])
        })
      }
      this.$emit("blurInput", _.union(commitResult));// TODO 不知道为啥这里会重复，返回处理后的数组
    },
    changeSearchText() {
      const self = this
      var text = this.searchText || ''
      var originData = this.originData || []
      self.tableData = []
      self.selectedArr = []
      if (text) {
        let keys = ['code','descript']
        originData.map(item => {
          keys.map(item2 => {
            let val = item[item2] || ''
            if (val && val.toLowerCase().indexOf(text.toLowerCase()) > -1) {
              item.tempFlag = true
            }
          })
        })
        let temp = []
        originData.map(item => {
          if (item.tempFlag) {
            delete item.tempFlag
            temp.push(item)
          }
        })
        this.tableData = temp
      } else {
        this.tableData = originData
      }
    },
    click() {
      this.controlShow = true
      this.init() // 初始化表格
    },
    putDefaultValue() { // 此方法只关心显示的content
      if (this.defaultValue) {
        this.selectKeyString = this.defaultValue
        let valueArr = this.defaultValue.split(',')

        if (this.keyIsNumber) {
          var temp = []
          valueArr.map((item) => {
            temp.push(Number(item))
          })
          valueArr = temp
        }
        let contentArr = []
        let self = this
        if (this.getListApi !== null && this.getListApi !== '') {
          this.AXIOS.post(this.getListApi, this.getListParams).then(res => {
            if (res) {
              // 将默认值对应其描述，然后显示出来
              let axiosResult = (self.axiosRes === null || self.axiosRes === undefined) ? res : res[self.axiosRes];
              if (axiosResult && axiosResult.length) {
                axiosResult.map((item, index) => {
                  if (valueArr.length !== 0) {
                    if (valueArr.includes(item[self.selectKey])) {
                      contentArr.push(item[self.showKey])
                      self.selectedArr.push(item)
                    }
                  }
                })
                if (self.alwaysShowDefault) {
                  self.content = self.defaultValue
                } else {
                  if (contentArr.length !== 0) {
                    self.content = contentArr.join(',')
                  }
                }
              }
            }
          })
        }
      }
    },
    setOriginData() {
      let self = this
      var temp = deepClone(self.tableData)
      temp.map((item, index) => {
        item.valStr = _.values(item).join('      ')
      })
      self.originData = temp
    },
    init() {
      // 如果数据已经初始化过了，就不要再去加载数据了，不然每次打开界面都重复加载数据
      if (this.initialized) return

      let self = this
      this.searchText = ''
      this.originData = []

      if (this.getListApi) {
        this.loading = true
        this.AXIOS.post(this.getListApi, this.getListParams).then(res => {
          if (res) {
            self.initialized = true

            // 把res中对应的字段塞到tableData中，如果是接口的结果是放在resultInfo中，就是res
            self.tableData = (self.axiosRes === null || self.axiosRes === undefined) ? res : res[self.axiosRes];
            self.setOriginData()
            if (self.selectedArr && self.selectedArr.length !== 0) {
              let selectedArrTemp = []
              for (let item of self.selectedArr) {
                selectedArrTemp.push(item[self.selectKey])
              }
              self.$refs.table.selectAll(false); // 勾选全部清除
              if (self.tableData.length !== 0) {
                for (let i = 0; i < self.tableData.length; i++) {
                  let item = self.tableData[i];
                  if (selectedArrTemp.includes(item[self.selectKey])) {
                    self.$nextTick(() => { // 必须nextTick，否则会得到两个选中值
                      self.$refs.table.toggleSelect(i)
                    })
                  }
                }
              }
            }
          }
        }).finally(res => {
          this.loading = false
        })
      }
    },
    invertSelection() { // 反选
      // 默认选中之前选中的行
      let selectedArrTemp = []
      this.selectedArr.forEach((item) => {
        selectedArrTemp.push(item[this.selectKey])
      })
      this.$refs.table.selectAll(false); // 勾选全部清除
      for (let i = 0; i < this.tableData.length; i++) {
        let item = this.tableData[i];
        if (!selectedArrTemp.includes(item[this.selectKey])) {
          this.$refs.table.toggleSelect(i)
        }
      }
    },
    handleSelectAll(status) {
      this.$refs.table.selectAll(status);
    },
    selectChange(selection) {
      // 单选时不能全选, (只有一条的话，就可以全选)
      if (this.isSingleSelect === true && selection && selection.length === this.originData.length && this.originData.length > 1) {
        this.$refs.table.selectAll(false); // 勾选全部清除
      } else {
        this.selectedArr = []
        this.selectedArr = selection
      }
    },
    selectSingleChange(selection, row) {
      if (this.isSingleSelect === true && this.selectSingleFlag === true) { // 如果是单选
        this.$refs.table.selectAll(false)// 勾选全部清除
        if (this.tableData.length !== 0) {
          for (let i = 0; i < this.tableData.length; i++) {
            let item = this.tableData[i];
            if (row[this.selectKey] === item[this.selectKey]) {
              this.selectSingleFlag = false
              this.$nextTick(() => { // 必须nextTick，否则会得到两个选中值
                this.$refs.table.toggleSelect(i)
              })
            }
          }
        }
      } else {
        this.selectSingleFlag = true
      }
    },

    clickRow(row, index) {
      this.$refs.table.toggleSelect(index)
    },

    confirm() { // 确定
      this.controlShow = false
      this.$emit("commit", this.selectedArr);// 返回数组
      let commitResult = []
      if (this.selectedArr.length !== 0) {
        this.selectedArr.map(item => {
          commitResult.push(item[this.selectKey])
        })
      }
      this.$emit("commitResult", commitResult);// 返回处理后的数组
      let result = []
      let selectKeyStringArr = []
      for (let item of this.selectedArr) {
        result.push(item[this.showKey])
        selectKeyStringArr.push(item[this.selectKey])
      }
      this.content = result.join(',')
      this.selectKeyString = selectKeyStringArr.join(',')
    },
    cancel() { // 取消
      this.controlShow = false
      // 默认选中之前选中的行
      let selectKeyStringArr = this.selectKeyString.split(',') // 之前选中的行
      this.$refs.table.selectAll(false); // 勾选全部清除
      if (this.tableData.length !== 0) {
        for (let i = 0; i < this.tableData.length; i++) {
          let item = this.tableData[i];
          if (selectKeyStringArr.includes(item[this.selectKey])) {
            this.$refs.table.toggleSelect(i)
          }
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>

</style>
