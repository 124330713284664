<template>
  <div style="display: inline-block;">
    <InputNumber
      v-model="minVal"
      v-bind="minProps"
      @on-change="changeMin"
    ></InputNumber>
    <slot name="joiner">
      <span :class="['kebab-line', joinerClass]"></span>
    </slot>
    <InputNumber
      v-model="maxVal"
      v-bind="maxProps"
      @on-change="emit"
      @on-blur="blurMax"
    ></InputNumber>
  </div>
</template>

<script>
export default {
  name: "CountRange",
  props: {
    value: Object,
    keyMap: {
      type: Object,
      default: () => ({
        min: "min",
        max: "max"
      })
    },
    disabled: Boolean,
    minDisabled: Boolean,
    maxDisabled: Boolean,
    minClass: [String, Array, Object],
    maxClass: [String, Array, Object],
    minHolder: {
      type: String,
      default: "最小值"
    },
    maxHolder: {
      type: String,
      default: "最大值"
    },
    min: Object,
    max: Object,
    joinerClass: [String, Object]
  },
  data() {
    return {
      minVal: null,
      maxVal: null
    };
  },
  computed: {
    minProps() {
      return {
        min: 0,
        max: 9999999999,
        placeholder: this.minHolder,
        disabled: this.disabled || this.minDisabled,
        class: this.minClass,
        ...this.min
      };
    },
    maxProps() {
      return {
        min: 0,
        max: 9999999999,
        placeholder: this.maxHolder,
        disabled: this.disabled || this.maxDisabled,
        class: this.maxClass,
        ...this.max
      };
    }
  },
  methods: {
    changeMin(val) {
      if (this.maxVal != null && val > this.maxVal) {
        this.maxVal = val + 1 > this.maxProps.max ? this.maxProps.max : val + 1;
      }
      this.emit();
    },
    blurMax() {
      if (this.maxVal != null && this.maxVal < this.minVal) {
        let temp = this.maxVal;
        this.maxVal = this.minVal;
        this.minVal = temp;
      }
      this.emit();
    },
    emit() {
      this.$emit("input", {
        ...this.value,
        [this.keyMap.min]: this.minVal,
        [this.keyMap.max]: this.maxVal
      });
    },
    setMin(newVal) {
      this.minVal = newVal !== undefined ? newVal : null;
    },
    setMax(newVal) {
      this.maxVal = newVal !== undefined ? newVal : null;
    }
  },
  created() {
    let unWatch = this.$watch(
      "keyMap",
      val => {
        // console.log("keymap");

        // 监听对应最小值
        this.$watch(`value.${val.min}`, this.setMin, {
          immediate: true
        });

        // 监听对应最大值
        this.$watch(`value.${val.max}`, this.setMax, {
          immediate: true
        });
      },
      {
        immediate: true
      }
    );
    unWatch();
  },
  mounted() {
    let i;
    for (i = 0; i < this.$el.children.length; ) {
      this.$el.parentNode.insertBefore(
        this.$el.children[this.$el.children.length - i - 1],
        this.$el.nextElementSibling
      );
    }
    this.$el.parentNode.removeChild(this.$el);
  }
};
</script>
