<template>
  <div>
    <Form ref="form" :model="form" :rules="formRule" :label-width="labelWidth">
      <Row>
        <FormItem
          label="开始日期"
          prop="beginDate"
          :rules="[{required: true, message: '请选择开始日期', trigger: 'change'}]"
        >
          <DatePicker
            type="date"
            :value="form.beginDate"
            show-week-numbers
            placement="bottom-start"
            placeholder="开始日期"
            style="width: 200px"
            @on-change="dateChange($event,'beginDate')"
          ></DatePicker>
        </FormItem>
        <FormItem
          style="margin-left: 12px"
          label="结束日期"
          prop="endDate"
          :rules="[{required: true, message: '请选择结束日期', trigger: 'change'}]"
        >
          <DatePicker
            :value="form.endDate"
            type="date"
            show-week-numbers
            placement="bottom-start"
            placeholder="结束日期"
            style="width: 200px"
            @on-change="dateChange($event,'endDate')"
          ></DatePicker>
        </FormItem>
        <div style="margin-left: 22px">
          <Button type="primary" icon="md-add" @click="addDate()"></Button>
        </div>
      </Row>
      <Row v-for="item in addedTimeArr" :key="item.key">
        <FormItem
          label="开始日期"
          :prop="'beginDate'+item.key"
          :rules="[{required: true, message: '请选择开始日期', trigger: 'change'}]"
        >
          <DatePicker
            type="date"
            :value="item.beginDate"
            show-week-numbers
            placement="bottom-start"
            placeholder="开始日期"
            style="width: 200px"
            @on-change="addedDateChang(item,$event,'beginDate')"
          ></DatePicker>
        </FormItem>
        <FormItem
          label="结束日期"
          style="margin-left: 12px"
          :prop="'endDate'+item.key"
          :rules="[{required: true, message: '请选择结束日期', trigger: 'change'}]"
        >
          <DatePicker
            :value="item.endDate"
            type="date"
            show-week-numbers
            placement="bottom-start"
            placeholder="结束日期"
            style="width: 200px"
            @on-change="addedDateChang(item,$event,'endDate')"
          ></DatePicker>
        </FormItem>
        <Button style="margin-left: 22px" type="error" icon="ios-trash" ghost @click="deleteDate(item)"></Button>
      </Row>
    </Form>
  </div>
</template>
<script>
export default {
  name: 'Name',
  props: {
    labelWidth: {
      type: Number,
      default: 80
    },
    adjustId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      form: {
        beginDate: '',
        endDate: ''
      },
      keyNum: 1,
      formRule: {},
      addedTimeArr: [] // 存放点击加号按钮增加的更多时间
    }
  },
  methods: {
    addDate() {
      let flag = true
      if (!this.form.beginDate || !this.form.endDate) { // 说明开始日期或结束日期没值
        flag = false
      }
      this.addedTimeArr.map(item => {
        if (!item.beginDate || !item.endDate) { // 存在没有填值的日期，就不能添加
          flag = false
        }
      })
      if (flag) {
        this.keyNum += 1
        let key = this.keyNum
        this.addedTimeArr.push({
          key: key,
          adjustId: this.adjustId,
          beginDate: '',
          endDate: ''
        })
        this.$set(this.form, 'beginDate' + key, '') // 必须在form中加，因为要做验证
        this.$set(this.form, 'endDate' + key, '')
      } else {
        // 做表单验证的操作去提醒用户输入
        this.$refs.form.validate()
      }
    },
    dateChange(event, type) { // form的开始日期和结束日期验证
      this.form[type] = event
      if (this.form[type]) {
        if (this.compareDate()) {
          this.$Message.warning('开始日期大于结束日期')
          this.$nextTick(() => {
            this.form[type] = ''
          })
        } else if (this.form.beginDate && this.form.endDate) { // 验证和增加的时间的交集
          let result = false
          this.addedTimeArr.map(oldItem => {
            if (oldItem.endDate && oldItem.beginDate && this.hasIntersection(this.form, oldItem)) { // 有交集返回true
              result = true;
            }
          })
          if (result) {
            this.$Message.warning('日期段内已包含，请重新选择')
            this.$nextTick(() => {
              this.form[type] = ''
            })
          } else { // 没有交集返回结果给父组件
            var weeks = this.getDateDuringWeeks()
            this.$emit('dateChange', this.form.beginDate, this.form.endDate, this.addedTimeArr, weeks)
          }
        }
      }
    },

    getDateDuringWeeks(){
      var a = [{
        beginDate : this.form.beginDate,
        endDate: this.form.endDate
      }]
      var b = this.addedTimeArr
      var c = a.concat(b)
      var allDates = []
      c.map((item, index) => {
        if(item.beginDate && item.endDate){
          var d = formatEveryDay(this.formatDateStr(item.beginDate), this.formatDateStr(item.endDate))
          allDates = allDates.concat(d)
        }
      })
      var weekTitle = allDates.map(item => {
        return this.getWeekTitle(item)
      })
      var weekNum = allDates.map(item => {
        return this.getWeekNum(item)
      })
      var temp1 = _.union(weekTitle)
      var temp2 = _.union(weekNum)
      return {
        weekTitle: temp1,
        weekNum: temp2
      }
    },
    getWeekTitle(v) {
      var weekarr = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      return weekarr[moment(v).day()]
    },
    getWeekNum(v) {
      return moment(v).day()
    },

    compareDate() { // 开始日期大于结束日期返回true
      let result = false
      let startdate = Date.parse(this.form.beginDate)
      let enddate = Date.parse(this.form.endDate)
      if (startdate >= enddate) {
        result = true
      }
      return result
    },
    hasIntersection(item, obj) { // 两个包含开始、结束时间的对象进行比较，有交集返回true
      let result = true
      let startdate = Date.parse(obj.beginDate)
      let enddate = Date.parse(obj.endDate)
      let itemStartDate = Date.parse(item.beginDate)
      let itemEndDate = Date.parse(item.endDate)
      if (itemEndDate < startdate) {
        result = false
      }
      if (itemStartDate > enddate) {
        result = false
      }
      return result
    },
    hasIntersectionSingle(item, obj, type) { // 单个日期传值，如果在日期段内返回true
      let result = false
      if (obj.beginDate && obj.endDate) {
        let startdate = Date.parse(obj.beginDate)
        let enddate = Date.parse(obj.endDate)
        let value = Date.parse(item[type])
        if (value >= startdate && value <= enddate) {
          result = true
        }
      }
      return result
    },
    addedDateChang(item, event, type) {
      item[type] = event
      this.form[type + item.key] = event
      let result = false
      if (item.beginDate && item.endDate) { // 当开始和结束都有值时
        let startdate = Date.parse(item.beginDate)
        let enddate = Date.parse(item.endDate)
        if (startdate >= enddate) { // 首先判断开始时间是否大于结束时间
          this.$Message.warning('开始日期大于结束日期')
          this.addedTimeArr.map(i => {
            if (i.key === item.key) {
              this.$nextTick(() => {
                i[type] = ''
              })
            }
          })
          this.$set(this.form, type + item.key, '')
        } else if (this.hasIntersection(item, this.form)) { // 判断和form的beginDate，endDate有无交集
          result = true
        } else { // 判断和增加的日期有无交集
          this.addedTimeArr.map(oldItem => {
            if (oldItem.key !== item.key && oldItem.endDate && oldItem.beginDate && this.hasIntersection(item, oldItem)) { // 有交集返回true
              result = true;
            }
          })
        }
      } else if (item[type]) { // 开始或者结束时间单独比较
        if (this.hasIntersectionSingle(item, this.form, type)) {
          result = true;
        } else {
          this.addedTimeArr.map(oldItem => {
            if (this.hasIntersectionSingle(item, oldItem, type)) { // 有交集返回true
              result = true;
            }
          })
        }
      }
      if (result) { // 有交集处理
        this.$Message.warning('日期段内已包含，请重新选择')
        this.addedTimeArr.map(i => {
          if (i.key === item.key) {
            this.$nextTick(() => {
              i[type] = ''
            })
          }
        })
        this.$set(this.form, type + item.key, '')
      } else {
        var weeks = this.getDateDuringWeeks()
        this.$emit('dateChange', this.form.beginDate, this.form.endDate, this.addedTimeArr, weeks)
      }
    },
    deleteDate(item) {
      this.addedTimeArr.map((i, index) => {
        if (i.key === item.key) {
          this.$nextTick(() => {
            this.addedTimeArr.splice(index, 1)
            var weeks = this.getDateDuringWeeks()
            this.$emit('dateChange', this.form.beginDate, this.form.endDate, this.addedTimeArr, weeks)
          })
        }
      })
    },
    validForm(callback) { // 用于父组件验证输入的时间
      this.$refs.form.validate(valid => {
        if (valid) {
          callback(true)
        } else {
          callback(false)
        }
      })
    },
    setDefaultValue(beginDate, endDate, subList) { // 设置默认值
      this.form.beginDate = beginDate
      this.form.endDate = endDate
      this.addedTimeArr = []
      if (subList.length !== 0) { // 必须重新塞key，不然验证不了
        subList.map(item => {
          this.keyNum += 1
          let key = this.keyNum
          this.addedTimeArr.push({
            key: key,
            adjustId: this.adjustId,
            beginDate: item.beginDate,
            endDate: item.endDate
          })
          this.$set(this.form, 'beginDate' + key, item.beginDate) // 必须在form中加，因为要做验证
          this.$set(this.form, 'endDate' + key, item.endDate)
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>

</style>
