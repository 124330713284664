<template>
  <div v-if="visibleContrl">
    <div class="choose_hotel_table">
      <Table
        ref="chooseHotelTable"
        border
        row-key="id"
        max-height="500"
        :show-header="false"
        :columns="chooseHotelColumns"
        :data="chooseHotelData"
        @on-row-click="clickRow"
      >
        <template :slot="showKey" slot-scope="{row}">
          <Checkbox v-model="hotelForm[row[modelKey]]"
                    @on-change="chooseHotelChange($event,row[showKey])">
             <span style="display: inline-block; margin-left: 5px;margin-right: 5px;">
               {{ row[showKey] }}
             </span>
           （ {{row.code || ''}}）
          </Checkbox>
        </template>
      </Table>
    </div>
    <div style="display: flex; justify-content: space-between;margin-top:20px;">
      <div>
        <Button @click="chooseHotelInvertSelect()">反选</Button>
        <Button @click="chooseHoteSelectAll()">全选</Button>
        <Button @click="chooseHotelSelectClear()">清空</Button>
      </div>
      <div>
        <Button @click="chooseHotelSelectQuit()">退出</Button>
        <Button type="primary" @click="chooseHotelCommit()">确定</Button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HotelTreeSelect",
  props: {
    visibleContrl: {
      type: Boolean,
      default: false
    },
    chooseHotelColumns: { // 列显示和插槽设置
      type: Array,
      default: () => {
        return [
          {
            title: "表头",
            slot: 'descript',
            tree: true
          }
        ]
      }
    },
    showKey: { // 插槽key和显示的key
      type: String,
      default: ''
    },
    modelKey: { // 单选框绑定的key
      type: String,
      default: ''
    },
    hotelListApi: { // 获取酒店列表的接口
      type: String,
      default: ''
    },
    hotelListParams: { // 获取酒店列表的参数
      type: Object,
      default: () => ({})
    }

  },
  data() {
    return {
      initialized: false,
      chooseHotelData: [],
      hotelForm: {}, // 用于存放选择同步的酒店表单
      originList: [] // 原数组
    }
  },
  watch: {
    'visibleContrl'(val) {
      if (val === true) {
        this.init()
      }
    }
  },
  created() {

  },
  methods: {
    init() {
      if (this.initialized) return

      if (this.hotelListApi !== '' && this.visibleContrl) {
        let self = this
        self.AXIOS.post(self.hotelListApi, self.hotelListParams).then(res => {
          self.initialized = true
          
          self.originList = res || []
          if (res) {
            this.chooseHotelData = res || []
            // 绑定属性
            if (res.length !== 0) {
              res.map(item => {
                this.hotelForm[item[self.modelKey]] = item[self.modelKey]
                this.hotelForm[item[self.modelKey]] = false
              })
            }
          }
        })
      }
    },
    chooseHotelChange() {
      this.chooseHotelData = [].concat(this.chooseHotelData) // 用于table重新渲染
    },
    chooseHotelInvertSelect() { // 选择同步的酒店反选
      for (let item in this.hotelForm) {
        if (item !== undefined && item !== null) {
          this.hotelForm[item] = !this.hotelForm[item]
        }
      }
      this.chooseHotelData = [].concat(this.chooseHotelData) // 用于table重新渲染
    },
    chooseHoteSelectAll() { // 选择同步的酒店全选
      for (let item in this.hotelForm) {
        if (item !== undefined && item !== null) {
          this.hotelForm[item] = true
        }
      }
      this.chooseHotelData = [].concat(this.chooseHotelData) // 用于table重新渲染
    },
    chooseHotelSelectClear() { // 选择同步的酒店清空
      for (let item in this.hotelForm) {
        if (item !== undefined && item !== null) {
          this.hotelForm[item] = false
        }
      }
      this.chooseHotelData = [].concat(this.chooseHotelData) // 用于table重新渲染
    },
    chooseHotelSelectQuit() {
      this.$emit("hotelTreeSelectQuit")
    },
    chooseHotelCommit() {
      var choosenRow = []
      var originList = this.originList || []
      originList.map((item, index) => {
        if (this.hotelForm[item.id]) {
          choosenRow.push(item)
        }
      })
      this.$emit("hotelTreeSelectCommit", this.hotelForm, choosenRow)
    },
    clickRow(row, index) {
      if (row.isHotel !== false) {
        for (let item in this.hotelForm) {
          if (item == row[this.modelKey]) { // 必须用==因为row[this.modelKey]可能为int类型
            this.hotelForm[item] = !this.hotelForm[item]
          }
        }
        this.chooseHotelData = [].concat(this.chooseHotelData) // 用于table重新渲染
      }
    }
  }
}
</script>
<style lang="less" scoped>
.choose_hotel_table{
 /deep/ .ivu-table-cell-slot{
   display: inline-block;
   vertical-align: middle;
 }
}
</style>
