import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["isAdmin", "isUnion", "isGroup", "userInfo"])
  },
  methods: {
    floatNum(num) {
      if (typeof num !== "string" || !num) num = (num || 0) + "";
      return /\./.test(num + "")
        ? /\.\d{2}/.test(num)
          ? num
          : num + "0"
        : num + ".00";
    },
    setAmountColor(num, useTip, transfer) {
      if (isNaN(num + "")) return;
      let span = (
        <span
          class={
            num > 0 ? "accent-limegreen" : num < 0 ? "accent-coral" : undefined
          }
        >
          {num}
        </span>
      );

      return !useTip ? (
        span
      ) : (
        <Tooltip content={num} transfer={transfer} class='ellipsis'>
          {span}
        </Tooltip>
      );
    }
  }
};
