import Vue from 'vue'
import Router from 'vue-router'
import index from './index'

import {LoadingBar} from 'view-design'

Vue.use(Router)

var router = new Router({
    routes: [
        ...index,
        //
        // //  麻痹404一定要放到最后，艹，
        // {
        //     path: "/*",
        //     name: "error404",
        //     meta: {
        //         title: "404-页面不存在"
        //     },
        //     component: () => import(/* webpackChunkName: "error404" */ "../views/error/404.vue")
        // },
    ]
})

var temp = window._HASHQUERY || {}
localStorage.setItem('language', temp.language || 'zh-CN')

// 路由切换
router.beforeEach((to, from, next) => {
    var query = to.query;
    LoadingBar.start()
    // 如果URL参数中有token 根据token去获取集团信息
    var token = query.token || ''
    var lastToken = localStorage.getItem('ucToken') || ''
    if(process.env.NODE_ENV == 'development'){
        token = query.token || 'eyJhbGciOiJIUzUxMiJ9.eyJkZXZpY2VUeXBlIjoiQ09NUFVURVIiLCJvcmdDb2RlIjoiR0NCWiIsImFwcENvZGUiOiJQTVNBVURJVCIsInVzZXJUeXBlIjoiTk9STUFMIiwicHJpbmNpcGFsVXNlckNvZGUiOiJHQ0JaX0FETUlOIiwiZXhwIjoxNjgwOTMyNTgzLCJ1c2VyQ29kZSI6IkdDQlpfQURNSU4ifQ.qbXCxXImL7YBb0PT46f9VePy275R0fxE4_e9MMc6uUUWoOz6vSjeFquKNncFRc0O7hX-qd7CNbGzojjCJWSDMA&language'
    }
    if(token){
       localStorage.setItem('ucToken', token)
    }
    if(token && (token != lastToken)){
        getUserInfo(token, next)
    } else {
        next()
        LoadingBar.finish()
    }
})

function getUserInfo(token, next) {
    AXIOS.post('hotelopen.basic.GetUserInfo', {
        token: token,
    }).then(res => {
        // appkey: "gop_audit"
        // fileServerAccess: "deviskaifa"
        // fileServerGroupid: "GCFILE"
        // fileServerUrl: "https://account.gcpms.top/"
        // hotelCode: ""
        // hotelDescript: ""
        // hotelGroupCode: "GCBZG"
        // hotelGroupDescript: "绿云标准版标准库集团b"
        // hotelGroupId: "2"
        // hotelId: ""
        // secret: "BoJyHbtHjfiEjPf6"
        // user: "GCBZG_ADMIN"
        localStorage.setItem('userInfo', JSON.stringify(res || {}))
        localStorage.setItem('hotelGroupId', res.hotelGroupId || '')
        localStorage.setItem('userCode', res.user || '')
        localStorage.setItem('hotelId', res.hotelId || '')
        getAuth()
    }).finally((res) => {
        next()
        LoadingBar.finish()
    })
}

function getAuth() {
    AXIOS.post('hotelopen.taskassign.getOperationsByToken', {
        ucToken: localStorage.getItem('ucToken') || ''
    }).then((res) => {
        localStorage.setItem('userAuth', JSON.stringify(res.rows || []))
    })
}

export default router
