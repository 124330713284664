<template>
  <div>
    <Input v-model="inputData" :placeholder="placeholderString" :readonly="inputReadOnly">
      <Button
        slot="append"
        icon="ios-arrow-down"
        :disabled="clickDisabledControl"
        @click="popupClick()"
      ></Button>
    </Input>
    <Modal
      :key="getListApi"
      v-model="ispopup"
      :title="title"
      :scrollable="true"
      :closable="false"
      :width="$store.state.modalWidth"
      height="700"
      :mask-closable="false"
    >
      <div>
        <div class="search-bar">
          <Input
            v-model="searchText"
            placeholder="查询"
            style="padding-bottom: 15px;"
            @on-change="changeSearchText"
          ></Input>
        </div>
        <Table
          :key="getListApi"
          ref="table"
          border
          :row-class-name="rowClassName"
          max-height="500"
          :columns="tableColumns"
          :data="tableData"
          :loading="loading"
          @on-row-click="onTableRowClick"
        >
          <template
            slot-scope="{ row }"
            slot="descriptEn"
          >{{row.descriptEn || row.descript_en || ''}}</template>
        </Table>
        <div v-if="pageEnabled" style="margin: 10px;overflow: hidden">
          <div style="float: right;">
            <Page
              :show-total="showTotal"
              :show-sizer="showSizer"
              :show-elevator="showElevator"
              :total="totalData.length"
              :current="currentPage"
              :page-size="pageSize"
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange"
            ></Page>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div style="display: flex; justify-content: space-between">
          <div>
            <!-- 单选没有反选和全选 -->
            <Button v-if="!(isSingleSelect === true)" @click="invertSelect()">反选</Button>
            <Button v-if="!(isSingleSelect === true)" @click="allSelect()">全选</Button>
            <Button @click="clearSelect()">清空</Button>
          </div>
          <div>
            <Button @click="cancel()">取消</Button>
            <Button type="primary" @click="confirm()">确定</Button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  name: 'CodeInputSelect',
  // 兼容InputListSelect组件 props属性保持不变
  props: {
    inputReadOnly: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'title'
    },
    placeholderString: {
      type: String,
      default: '请输入'
    },
    getListApi: {
      type: String,
      default: null
    },
    getListParams: {
      type: Object,
      default: () => ({})
    },
    columnsArr: {
      type: Array,
      default: () => {
        return [
          {
            title: '代码',
            key: 'code'
          },
          {
            title: '描述',
            key: 'descript'
          },
          {
            title: '英文描述',
            key: 'descriptEn',
            slot: 'descriptEn'
          }
        ]
      }
    },
    selectKey: {
      type: String,
      default: 'code'
    },
    showKey: {
      type: String,
      default: 'code'
    },
    axiosRes: {
      type: String,
      default: null
    },
    isSingleSelect: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: String,
      default: null
    },
    clickDisabledControl: {
      type: Boolean,
      default: false
    },
    showTotal: {
      type: Boolean,
      default: true
    },
    showSizer: {
      type: Boolean,
      default: true
    },
    showElevator: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      keyIndex: 0,
      ispopup: false, // 是否弹出
      initialized: false, // 数据是否初始化
      loading: false, // 数据是否加载中
      inputData: null, // 输入框显示内容
      totalData: [], // 从接口加载的所有数据
      currentPage: 0, // 当前页码
      pageSize: 10, // 默认每页显示10条
      searchTimerID: null, // 搜索防抖计时器
      searchText: '',
      searchKey: ''
    }
  },
  computed: {
    pageEnabled() {
      if (this.totalData.length <= 10) return false
      if (this.searchKey && this.searchKey.length > 0) return false
      return true
    },
    tableColumns() {
      return [
        {
          title: '选中',
          key: 'selected',
          align: 'center',
          render: (h, params) => {
            return h('Checkbox', { props: { value: params.row._checked } })
          }
        },
        ...this.columnsArr
      ]
    },
    tableData() {
      if (this.currentPage === 0) return []

      if (this.searchKey && this.searchKey.length > 0) {
        // 搜索数据
        const searchData = this.totalData.filter(
          (data) => JSON.stringify(data).indexOf(this.searchKey) >= 0
        )
        return searchData.slice(0, 10) // 最多只显示10条搜索结果
      } else {
        // 分页数据
        return this.totalData.slice(
          this.pageSize * (this.currentPage - 1),
          this.pageSize * this.currentPage
        )
      }
    }
  },
  created() {
    // 如果有传入默认值, 组件创建的时候就需要初始化数据,不然不知道选中的对象描述,input内容也显示不出来
    if (this.defaultValue && this.defaultValue.length > 0) this.init()
  },
  methods: {
    popupClick() {
      this.ispopup = true
      this.init()
    },
    init() {
      // 如果数据已经初始化过了，就不要再去加载数据了
      if (this.initialized) return

      let self = this

      if (this.getListApi) {
        this.loading = true
        this.AXIOS.post(this.getListApi, this.getListParams)
          .then((res) => {
            if (res) {
              self.initialized = true // 数据加载成功, 初始化标记置为true

              self.totalData =
                self.axiosRes === null || self.axiosRes === undefined
                  ? res
                  : res[self.axiosRes]

              if (self.totalData && self.totalData.length > 0) {
                self.totalData.forEach((data) => {
                  data._key = 'key' + self.keyIndex++
                })
                self.currentPage = 1
              } else {
                self.totalData = []
                self.currentPage = 0
              }

              self.resetSelect()
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    // 分页页码发生变化
    onPageChange(page) {
      this.currentPage = page
    },
    onPageSizeChange(size) {
      this.pageSize = size
    },
    // 单击表格行
    onTableRowClick(row) {
      const newChecked = !row._checked
      const newTotalData = [...this.totalData]

      if (this.isSingleSelect && newChecked) {
        newTotalData.forEach((data) => {
          data._checked = false
        })
      }

      const newRow = newTotalData.find((nd) => nd._key === row._key)
      if (newRow) newRow._checked = newChecked

      this.totalData = newTotalData
    },
    // 点击选中所有
    allSelect() {
      const newTotalData = [...this.totalData]

      newTotalData.forEach((data) => {
        data._checked = true
      })

      this.totalData = newTotalData
    },
    // 点击反选
    invertSelect() {
      const newTotalData = [...this.totalData]

      newTotalData.forEach((data) => {
        data._checked = !data._checked
      })

      this.totalData = newTotalData
    },
    // 点击清除选中
    clearSelect() {
      const newTotalData = [...this.totalData]

      newTotalData.forEach((data) => {
        data._checked = false
      })

      this.totalData = newTotalData
    },
    // 重置选中状态
    resetSelect() {
      const newTotalData = [...this.totalData]
      const defaultData = this.defaultValue ? this.defaultValue.split(',') : []

      newTotalData.forEach((data) => {
        const code = defaultData.find((c) => c === data[this.selectKey])
        if (code) data._checked = true
        else data._checked = false
      })

      this.totalData = newTotalData

      this.inputData = this.totalData
        .filter((data) => data._checked)
        .map((data) => data[this.showKey])
        .join(',')
    },
    // 点击确认
    confirm() {
      this.ispopup = false

      // 避免污染数据 剔除 _checked _key
      const selectedData = this.totalData
        .filter((data) => data._checked)
        .map((data) => {
          const newData = { ...data }
          delete newData._checked
          delete newData._key
          return newData
        })

      this.inputData = this.totalData
        .filter((data) => data._checked)
        .map((data) => data[this.showKey])
        .join(',')

      this.$emit('commit', selectedData)

      this.$emit(
        'commitResult',
        selectedData.map((data) => data[this.selectKey])
      )
    },
    // 点击取消
    cancel() {
      this.ispopup = false
      this.resetSelect()
    },
    changeSearchText() {
      // TODO _.debounce 防抖函数不起效果？？？

      // 手动一个防抖
      clearTimeout(this.searchTimerID)
      this.searchTimerID = setTimeout(() => {
        this.searchKey = this.searchText
      }, 200)
    },
    rowClassName(row) {
      if (row._checked) return 'table-selected-row'
      else return ''
    }
  }
}
</script>
<style>
.ivu-table .table-selected-row td {
  background-color: #ebf7ff;
}
</style>
