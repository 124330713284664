const requireFile = require.context("./", false, /\/[a-zA-Z]+.vue$/);

let excludes = ["ConfirmModal.deprecated", "Lang"];
let arr = [];
requireFile.keys().forEach(e => {
  !excludes.some(name => e.includes(`/${name}.vue`)) &&
    arr.push(requireFile(e).default);
});

export default arr;
