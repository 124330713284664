
<template>
  <div class="fun-table-container">
    <Table
      ref="table"
      v-bind="$attrs"
      :border="border"
      :columns="tableColumns"
      :data="table.data"
      :loading="table.loading"
      :max-height="table.maxHeight"
      v-on="$listeners"
    >
      <template v-if="$slots.header" slot="header">
        <slot name="header" />
      </template>
      <template v-if="$slots.footer" slot="footer">
        <slot name="footer" />
      </template>
    </Table>
    <Page
      class="fun-table-page-right"
      :current.sync="page.curr"
      :total="page.total"
      :page-size="page.size"
      show-total
      show-sizer
      show-elevator
      transfer
      :page-size-opts="pageSizeOpts"
      @on-change="changePage"
      @on-page-size-change="changePageSize"
    />
  </div>
</template>

<script>
import { getPath } from '@/utils'

export default {
  name: 'FunTable',
  inheritAttrs: false,
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    requestMethod: Function,
    initParam: {
      type: Object,
      default: () => ({})
    },
    refresh: Function,
    dataPath: String,
    pagePath: String,
    border: {
      type: Boolean,
      default: true
    },
    process: Function,
    getParam: Function,
    loading: Boolean,
    autoMaxHeight: {
      type: Boolean,
      default: true
    },
    isLocal: Boolean, // 是否本地分页
    pageSizeOpts: {
      type: Array,
      default: () => [30, 50, 100, 200]
    },
    getPage: Function,
    autoRemain: Boolean,
    setHeight: Function,
    pageMap: {
      type: Object,
      default: () => ({
        firstResult: 'firstResult',
        pageSize: 'pageSize'
      })
    },
    heightDiff: {
      type: [Number, String],
      default: 60
    },
    pretreatment: Function,
    maxHeight: [Number, String],
    selection: Array,
    initSize: Number,
    check: Function, // 远程查询时额外检测的方法
    usePageNum: Boolean // 查询时是否用页码而不是用当页第一条的索引
  },
  data() {
    return {
      table: {
        data: [],
        rows: [],
        // selections: [],
        loading: false,
        maxHeight: null,
        selectType: {
          align: 'center',
          className: 'fun-table-mr-zero',
          renderHeader: h => {
            let checkableList = this.table.rows.filter(
              e => e._checkable && !e._disabled
            )
            let len = checkableList.length
            return (
              this.table.rows.some(e => e._checkable) &&
              h('Checkbox', {
                props: {
                  disabled: !len,
                  value: !!len && checkableList.every(e => e._checked)
                },
                on: {
                  input: val => {
                    checkableList.forEach(e => {
                      e._checked = val
                    })
                    this.$emit('toggle-checkboxes', this.table.rows, val)
                    this.selectRow()
                  }
                }
              })
            )
          },
          render: (h, { row }) => {
            return (
              row._checkable &&
              h('Checkbox', {
                props: {
                  value: row._checked,
                  disabled: row._disabled
                },
                on: {
                  input: val => {
                    this.$emit('toggle-checkbox', row, val)
                    this.selectRow(row, val)
                  }
                }
              })
            )
          }
        }
      },
      page: {
        curr: 1,
        size: 30,
        total: 0
      }
    }
  },
  computed: {
    tableColumns() {
      let selectType = this.columns.filter(e => e.type === 'selection')
      if (selectType.length) {
        let type = {
          fixed: selectType[0].fixed,
          width: selectType[0].width || 54,
          key: selectType[0].key,
          ...this.table.selectType
        }
        return [type].concat(this.columns.slice(1))
      }
      return this.columns
    }
  },
  watch: {
    data: {
      handler(val) {
        let data = Array.isArray(val) ? val : []
        if (this.isLocal) {
          this.page.curr = 1
          this.page.total = data.length
          this.handleData(data)
          this.getLocalData()
          return
        }
        if (!this.isFromRemote) {
          this.page.curr = 1
          this.page.total = data.length
          this.$nextTick(() => {
            this.table.rows = this.$refs.table.rebuildData
          })
          this.table.data = data
        }
      },
      immediate: true
    },
    loading(val) {
      this.table.loading = val
    },
    maxHeight: {
      handler(val) {
        this.table.maxHeight = val
      },
      immediate: true
    }
  },
  created() {
    this.$emit('update:refresh', this.refreshData)
    this.$emit('update:getRows', () => this.table.rows)
    this.$emit('update:getPage', () => ({
      ...this.getPageParam(),
      curr: this.page.curr,
      total: this.page.total
    }))
    this.$emit('update:setHeight', this.setMaxHeight)
    this.page.size = this.initSize || this.pageSizeOpts[0]
  },
  mounted() {
    !this.maxHeight && this.autoMaxHeight && this.setMaxHeight()
  },
  methods: {
    async getRemoteData() {
      if (typeof this.requestMethod !== 'function') { return console.warn("typeof requestMethod isn't function") }
      this.table.rows = []
      // this.table.selections = [];
      this.table.loading = true
      this.$emit('update:loading', true)
      this.$emit('update:selection', [])
      let param = Object.assign(
        this.getPageParam(),
        typeof this.getParam !== 'function' ? this.initParam : this.getParam()
      )
      let res = await this.requestMethod(param)
      this.table.loading = false
      this.$emit('update:loading', false)
      if (!res) {
        if (!this.autoRemain) {
          this.page.total = 0
          this.$emit('update:data', [])
        }
        return
      }
      this.pretreatment && this.pretreatment(res)
      let data =
        (this.dataPath == undefined
          ? res.datas
          : getPath(res, this.dataPath)) || []
      this.handleData(data)
      if (typeof this.process === 'function') {
        let processed = this.process(data)
        if (Array.isArray(processed)) {
          data = processed
        }
      }
      this.table.data = data
      this.page.total = !this.pagePath
        ? res.total
        : getPath(res, this.pagePath)
      this.$nextTick(() => {
        this.table.rows = this.$refs.table.rebuildData
      })
      this.isFromRemote = true
      this.$emit('update:data', this.table.data)
      this.$emit('load', res)
      setTimeout(() => {
        this.isFromRemote = false
      }, 0)
    },
    handleData(data) {
      this.columns.some(e => e.type === 'selection') &&
        data.forEach(e => {
          e._checkable = true // 是否显示checkbox
          e._checked = e._disabled = false
        })
    },
    getData(isPaging) {
      if (typeof this.check === 'function' && !this.check()) return
      !this.isLocal ? this.getRemoteData() : this.getLocalData(isPaging)
    },
    getLocalData(isPaging) {
      let arr = []
      let i = isPaging ? this.page.size * (this.page.curr - 1) : 0
      let temp = i + this.page.size
      let gap = temp > this.page.total ? this.page.total : temp

      for (; i < gap; i++) {
        this.data[i]._checked = this.data[i]._disabled = false
        arr.push(this.data[i])
      }
      // this.table.selections = [];
      this.table.data = arr
      this.$emit('update:selection', [])
      this.$nextTick(() => {
        this.table.rows = this.$refs.table.rebuildData
      })
    },
    changePage(page) {
      this.getData(true)
    },
    changePageSize(size) {
      this.page.size = size
      this.page.curr === 1 && this.getData()
    },
    refreshData() {
      this.page.curr = 1
      this.getRemoteData()
    },
    selectRow(row, val) {
      if (row) {
        row.hasOwnProperty('_checked')
          ? (row._checked = val)
          : this.$set(row, '_checked', val)
      }
      // console.log(row, val);
      let selections = this.table.rows.filter(e => e._checked)
      this.$emit('update:selection', selections)
      this.$emit('on-select-rows', selections)
    },
    setMaxHeight() {
      this.$nextTick(() => {
        this.table.maxHeight =
          window.innerHeight -
          this.heightDiff -
          this.$refs.table.$el.getBoundingClientRect().top
      })
    },
    getPageParam() {
      return {
        [this.pageMap.firstResult || 'firstResult']: !this.usePageNum
          ? this.page.size * (this.page.curr - 1)
          : this.page.curr,
        [this.pageMap.pageSize || 'pageSize']: this.page.size
      }
    }
  }
}
</script>
<style lang="less">
.fun-table- {
  &page-right {
    margin-top: 10px;
    text-align: right;
  }
  &mr-zero .ivu-checkbox-wrapper {
    margin-right: 0;
  }
}
</style>
