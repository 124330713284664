<template>
  <div class="HotelMultipleSelectModal">
    <Modal
      v-model="show"
      title="请选择需要分发的酒店"
      width="1000"
    >
      <Row :gutter="16">
        <Col span="8">
          <RadioGroup v-model="currentMode" style="margin-bottom: 15px;">
            <Radio label="hotelMode">酒店模式</Radio>
            <!--      TODO      <Radio label="brandMode">品牌模式</Radio>-->
          </RadioGroup>

          <Tabs class="ivu-tabs-multiple-hotel">
            <TabPane label="品牌">
              <CheckboxGroup v-model="localQuery.brandCode">
                <Checkbox
                  v-for="(item, index) in brandList"
                  :key="'brandList' + index"
                  :label="item.label"
                >
                  {{ item.desc }}
                </Checkbox>
              </CheckboxGroup>
            </TabPane>
          </Tabs>
          <Tabs class="ivu-tabs-multiple-hotel">
            <TabPane label="管理类型">
              <CheckboxGroup v-model="localQuery.manageType">
                <Checkbox
                  v-for="(item, index) in manageList"
                  :key="'manageList' + index"
                  :label="item.label"
                >
                  {{ item.desc }}
                </Checkbox>
              </CheckboxGroup>
            </TabPane>
          </Tabs>
          <Tabs class="ivu-tabs-multiple-hotel">
            <TabPane label="区域">
              <CheckboxGroup v-model="localQuery.area1">
                <Checkbox
                  v-for="(item, index) in area1List"
                  :key="'area1List' + index"
                  :label="item.label"
                >
                  {{ item.desc }}
                </Checkbox>
              </CheckboxGroup>
            </TabPane>
          </Tabs>
          <Tabs class="ivu-tabs-multiple-hotel">
            <TabPane label="地区">
              <CheckboxGroup v-model="localQuery.area2">
                <Checkbox
                  v-for="(item, index) in area2List"
                  :key="'area2List' + index"
                  :label="item.label"
                >
                  {{ item.desc }}
                </Checkbox>
              </CheckboxGroup>
            </TabPane>
          </Tabs>
          <Tabs class="ivu-tabs-multiple-hotel">
            <TabPane label="城市">
              <CheckboxGroup v-model="localQuery.cityCode">
                <Checkbox
                  v-for="(item, index) in cityList"
                  :key="'cityList' + index"
                  :label="item.label"
                >
                  {{ item.desc }}
                </Checkbox>
              </CheckboxGroup>
            </TabPane>
          </Tabs>
        </Col>

        <Col span="16">
          <div style="overflow: hidden">
            <Button type="primary" style="width: 100px" @click="doLocalSearch">查询</Button>
            <div style="margin-bottom: 5px; margin-right: 10px; float: right; width: 280px;">
              <input-list-select
                ref="multipleHotelListSelect"
                title="酒店快速选择"
                placeholder-string="酒店快速选择"
                get-list-api="/extapi/v1/ratecode/quickHotelSelect"
                :columns-arr="colums"
                :is-single-select="true"
                show-key="code"
                :input-read-only="false"
                :default-value="content"
                @commit="commit"
              ></input-list-select>
            </div>
          </div>
          <Transfer
            filterable
            :data="defaultHotelList"
            :list-style="listStyle"
            :target-keys="targetKeys"
            :render-format="renderItem"
            :filter-method="filterMethod"
            @on-change="handleChange1"
          ></Transfer>
        </Col>
      </Row>

      <template #footer>
        <Button @click="show=false">取消</Button>
        <Button type="primary" @click="doConfirm">确定</Button>
      </template>
    </Modal>
    <Modal v-model="confirmModalShow" title="提示" width="560">
      <div style="height: 100px; font-size: 18px;">
        是否将选择结果保存，方便下次使用？
      </div>
      <div slot="footer">
        <Button @click="confirmModalShow=false">取消</Button>
        <Button
          v-if="currentSelectName"
          type="primary"
          @click="doConfirmNext('cover')"
        >覆盖到 {{ currentSelectName }}
        </Button>
        <Button type="primary" @click="doConfirmNext('random')">另存为 {{ currentSelectName == name ? '随机' : name }}
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "HotelMultipleSelectModal",
  components: {},
  model: {
    prop: "visible"
  },
  props: {
    visible: Boolean,
    hotelCode: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: '/extapi/v1/ratecode/log/list'
    },
    defaultName: {
      type: String,
      default: ''
    },
    placeholderString: { // input提示
      type: String,
      default: '请选择酒店MODAL'
    }
  },
  data() {
    return {
      confirmModalShow: false,
      colums: [{
        type: 'selection',
        width: 60,
        align: 'center'
      }, {
        title: '代码',
        key: 'code'
      }, {
        title: '描述',
        key: 'descript'
      }],
      listStyle: {
        width: '280px',
        height: '500px'
      },
      content: '',
      show: false, // 是否显示
      loading: false, // 日志列表加载状态
      listData: [],
      listHotelsNoPicArr: [],

      // 筛选框
      currentMode: 'hotelMode',
      filterItemList: [], // 默认的筛选

      brandList: [], // 品牌  brandCode: "002", brandDes: "商务酒店"
      manageList: [], // 管理类型   manageType: "3", manageDes: "直接管理",
      area1List: [], // 区域  area1: "A1" area1Des: "浙江区"
      area2List: [], // 地区 area2: "W3" area2Des: "黄埔"
      cityList: [], // 城市 cityCode: "HZZJ1"  city: "杭州"
      localQuery: {
        brandCode: [],
        manageType: [],
        area1: [],
        area2: [],
        cityCode: []
      },

      // 左右切换
      originLeftList: [], // 默认的酒店列表
      defaultHotelList: [], // 默认的酒店列表
      targetKeys: [],

      currentSelectName: '',
      originRightList: [], // 当前快捷选中的酒店
      name: '', // 自己编辑的名字

      targetLastKeys: [],
      targetLastHotels: []
    };
  },
  watch: {
    visible(val) {
      if (val) this.click()
    },
    show(val) {
      this.show = val;
      if (val) {
        this.resetForm()
      }
      this.$emit("input", val);
    }
  },
  mounted() {
    if (this.defaultName) {
      this.currentSelectName = this.defaultName || ''
      this.name = this.defaultName || ''
      this.content = this.defaultName || ''
    }
  },
  methods: {
    doLocalSearch() {
      var targetKeys = this.targetKeys || []
      var targetLastKeys = deepClone(targetKeys)
      var targetLastHotels = this.getSelectedHotels()
      var originLeftList = this.originLeftList || []
      var localQuery = this.localQuery || {}
      var temp = []
      // 循环判断，内部是并集，外部是交集
      originLeftList.map((item) => {
        var withBrandCode = false
        var withManageType = false
        var withArea1 = false
        var withArea2 = false
        var withCityCode = false

        if (localQuery.brandCode.length) {
          withBrandCode = localQuery.brandCode.indexOf(item.brandCode) > -1
        } else {
          withBrandCode = true
        }

        if (localQuery.manageType.length) {
          withManageType = localQuery.manageType.indexOf(item.manageType) > -1
        } else {
          withManageType = true
        }

        if (localQuery.area1.length) {
          withArea1 = localQuery.area1.indexOf(item.area1) > -1
        } else {
          withArea1 = true
        }

        if (localQuery.area2.length) {
          withArea2 = localQuery.area2.indexOf(item.area2) > -1
        } else {
          withArea2 = true
        }

        if (localQuery.cityCode.length) {
          withCityCode = localQuery.cityCode.indexOf(item.cityCode) > -1
        } else {
          withCityCode = true
        }

        if (withBrandCode && withManageType && withArea1 && withArea2 && withCityCode) {
          temp.push(item)
        }
      })

      // 之前选中的数据倒插回去
      if (targetLastHotels.length) {
        var tempIds = _.pluck(temp, 'id')
        var needArr = []
        targetLastHotels.map((item) => {
          if (tempIds.indexOf(item.id) < 0) {
            needArr.push(item)
          }
        })
        temp = temp.concat(needArr)
      }

      this.defaultHotelList = temp
      this.targetKeys = targetLastKeys
    },
    commit(row) {
      var item = row[0] || {}
      var code = item.code || ''
      this.currentSelectName = code || ''
      if (this.currentMode == 'hotelMode') {
        this.getHotelSelectHotel(code) // 酒店模式
      } else {
        this.getHotelModSelectHotel(code) // 品牌模式
      }
    },
    getHotelSelectHotel(item) {
      const self = this
      // https://yapi.ihotel.cn/project/142/interface/api/22093
      this.AXIOS.post('/extapi/v1/ratecode/findSelectHotel', {
        hotelCode: '',
        items: item
      }).then(res => {
        if (res == '.') {
          res = ''
        }
        var temp = (res || '').split(',')
        self.targetKeys = temp || []
        self.originRightList = deepClone(temp || [])
      })
    },
    getHotelModSelectHotel(item) {
      // const self = this
      // https://yapi.ihotel.cn/project/142/interface/api/22093
      this.AXIOS.post('/extapi/v1/ratecode/findSelectHotelMod', {
        hotelCode: '',
        items: item
      }).then(res => {
        // if (res == '.') {
        //   res = ''
        // }
        // var temp = (res || '').split(',')
        // temp = temp.map(item => {
        //   return item += ''
        // })
        // self.targetKeys = temp || []
      })
    },
    click() {
      this.show = true
      this.listHotelsNoPic()
    },
    listHotelsNoPic() {
      const self = this
      // var row = this.row || {}
      this.loading = true
      // https://yapi.ihotel.cn/project/142/interface/api/22087
      this.AXIOS.post('/extapi/v1/ratecode/listHotelsNoPic', {}).then(res => {
        var arr = deepClone(res || [])
        this.setFilterList(arr)
        this.setHotelList(arr)
      }).finally(res => {
        self.loading = false
      })
    },
    setFilterList(res) {
      var origin = deepClone(res || [])
      this.filterItemList = origin
      this.brandList = this.formatFilterList('brandCode', 'brandDes')
      this.manageList = this.formatFilterList('manageType', 'manageDes')
      this.area1List = this.formatFilterList('area1', 'area1Des', 1)
      this.area2List = this.formatFilterList('area2', 'area2Des', 2)
      this.cityList = this.formatFilterList('cityCode', 'city')
      console.log('brandList', this.brandList)
      console.log('manageList', this.manageList)
      console.log('area1List', this.area1List)
      console.log('area2List', this.area2List)
      console.log('cityList', this.cityList)
      // brandList: [], // 品牌  brandCode: "002", brandDes: "商务酒店"
      //     manageList: [], // 管理类型  manageDes: "直接管理", manageType: "3"
      //     area1List: [], // 区域  area1: "A1" area1Des: "浙江区"
      //     area2List: [], // 地区 area2: "W3" area2Des: "黄埔"
      //     cityList: [], // 城市  city: "杭州" cityCode: "HZZJ1"
    },
    formatFilterList(code, des, type) {
      var origin = this.filterItemList || []
      var codeArr = _.compact(_.uniq(_.pluck(origin, code)))
      var temp = []
      codeArr.map((item) => {
        var tempDes = ''
        origin.map((item2) => {
          if (item2[code] == item) {
            if (type == 1) {
              tempDes = item2.brandDes + '-' + item2[des]
            } else if (type == 2) {
              tempDes = item2.brandDes + '-' + item2.area1Des + '-' + item2[des]
            } else {
              tempDes = item2[des]
            }
          }
        })
        temp.push({
          label: item,
          desc: tempDes
        })
      })
      return temp
    },
    setHotelList(res) {
      res.map((item, index) => {
        item.key = item.id + '' // 字符串匹配
        item.id = item.id + '' // 字符串匹配
        item.label = item.descript
        item.className = ''
      })
      this.defaultHotelList = deepClone(res || [])
      this.originLeftList = deepClone(res || [])
    },
    renderItem(item) {
      return '（' + item.code + '） ' + item.descript;
    },
    filterMethod(data, query) {
      var code = (data.code || '').toLowerCase()
      var descript = (data.descript || '').toLowerCase()
      var temp = query.toLowerCase()
      return code.indexOf(temp) > -1 || descript.indexOf(temp) > -1;
    },
    handleChange1(newTargetKeys, direction, moveKeys) {
      this.targetKeys = newTargetKeys;
    },
    getSelectedHotels() {
      var targetKeys = this.targetKeys
      var hotels = this.defaultHotelList
      var selectedHotels = []
      hotels.map((item) => {
        if (targetKeys.indexOf((item.id + '')) > -1) {
          selectedHotels.push(item)
        }
      })
      return selectedHotels
    },
    doConfirm() {
      var selectedHotels = this.getSelectedHotels()
      var name = this.$refs.multipleHotelListSelect.getContent()
      this.name = name || ''
      // 没选酒店
      if (!selectedHotels.length) {
        // 直接传个空出去
        // this.$emit('confirm', [])
        this.$Message.warning('请选择酒店')
        return false
      }
      if (name) {
        this.showConfirmModal() // 走下一步
      } else {
        this.$Message.warning('请输入一个名字作为选择结果的名称')
        return false
      }
    },
    showConfirmModal() {
      var originRightList = deepClone(this.originRightList || [])
      var targetKeys = deepClone(this.targetKeys || [])
      originRightList = originRightList.map((item) => {
        return item + ''
      })
      targetKeys = targetKeys.map((item) => {
        return item + ''
      })
      // 判断新选的跟老的数组有没有变化
      if (originRightList.length != targetKeys.length) {
        this.confirmModalShow = true
      } else {
        var union = _.union(originRightList, targetKeys);
        if (targetKeys.length == union.length) {
          // this.$Message.info('数组没变')
          if (this.currentSelectName != this.name) {
            this.confirmModalShow = true
          } else {
            this.doEmitConfirm()
          }
        } else {
          this.confirmModalShow = true
        }
      }
    },
    getNewName(type) {
      var name = this.name
      var currentSelectName = this.currentSelectName
      if (type == 'cover') {
        name = currentSelectName
      } else if (type == 'random') {
        if (name == currentSelectName) {
          // name = 'ADMIN' + new Date().getTime() // 用的登录用户名 作为前缀，这里就先不做了
          name = new Date().getTime() + ''
        }
      }
      return name
    },
    doConfirmNext(type) {
      var self = this
      var hotels = this.getSelectedHotels()
      var ids = _.pluck(hotels, 'id')
      var idsStr = ids.join(',')
      this.confirmModalShow = true
      var newName = this.getNewName(type)
      self.AXIOS.post('/extapi/v1/ratecode/adjust/saveHotelSelectCode', {
        hotelCode: '',
        hotelIds: idsStr,
        code: newName
      }).then(res => {
        self.$Message.info('保存成功')
        self.doEmitConfirm()
        self.confirmModalShow = false
        self.doAfter(newName, ids)
      })
    },
    doAfter(newName, ids) {
      var self = this
      setTimeout(() => {
        self.name = newName
        self.content = newName
        self.currentSelectName = newName
        self.originRightList = ids
      }, 400)
    },
    doEmitConfirm() {
      var self = this
      var hotels = this.getSelectedHotels()
      var ids = _.pluck(hotels, 'id')
      var idsStr = ids.join(',')
      self.content = self.name
      self.show = false
      self.$emit('confirm', self.content, hotels, ids, idsStr)
    },
    resetForm() {
      this.localQuery = {
        brandCode: [],
        manageType: [],
        area1: [],
        area2: [],
        cityCode: []
      }
      if (this.defaultName) {
        this.getHotelSelectHotel(this.defaultName)
      }
    }
  }
};
</script>
<style lang="less">
.ivu-tabs-multiple-hotel {
  .ivu-tabs-bar {
    margin-bottom: 0;
  }
  .ivu-tabs-content {
    padding: 5px;
    min-height: 65px;
    max-height: 100px;
    overflow-y: auto;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-top: 0;
  }
}
</style>
